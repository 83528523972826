import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormHandleSubmit,
} from "react-hook-form";
import CustomInput from "../CustomInput/customInput";
import { InputTypeEnum, IReasonFormValues } from "../interfaces";

interface IProps {
  errors: FieldErrors<IReasonFormValues>;
  control: Control<FieldValues, IReasonFormValues>;
  onSubmit: (data: FieldValues | IReasonFormValues) => void;
  handleSubmit: UseFormHandleSubmit<FieldValues | IReasonFormValues, undefined>;
}

const ReasonForm: React.FC<IProps> = (props) => {
  const { control, errors, handleSubmit, onSubmit } = props;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomInput
        type={InputTypeEnum.text}
        controllerName="tag"
        control={control}
        controllerRules={{ required: "Պարտադիր է" }}
        label="Լրացնել բանալի բառը"
        errors={errors}
      />
      <CustomInput
        type={InputTypeEnum.text}
        controllerName="title_am"
        control={control}
        controllerRules={{ required: "Պարտադիր է" }}
        label="Պատճառը հայերեն"
        errors={errors}
      />
      <CustomInput
        type={InputTypeEnum.text}
        controllerName="title_en"
        control={control}
        controllerRules={{ required: "Պարտադիր է" }}
        label="Պատճառն անգլերեն"
        errors={errors}
      />
      <CustomInput
        type={InputTypeEnum.text}
        controllerName="title_ru"
        control={control}
        controllerRules={{ required: "Պարտադիր է" }}
        label="Պատճառը ռուսերեն"
        errors={errors}
      />
    </form>
  );
};

export default ReasonForm;
