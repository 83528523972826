import { ILicense } from "../../interfaces";
import { downloadImageFromBunny } from "../../../utils/downloadImgFromBunny";

const UseDoctorsCertificationsHooks = () => {

  const handleAddCertificate = (index: number) => {
    const fileInput = document.getElementById(`certifications-fileUpload-${index}`) as HTMLInputElement;
    fileInput.click();
  };

  const handleDownload = (license: ILicense) => {
    try {
      const licenseUrl = downloadImageFromBunny({ avatar: license });
      if (licenseUrl) {
        const link = document.createElement("a");
        link.href = licenseUrl;
        link.download = "license.pdf";
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Upload failed:", error);
    }
  };

  return {
    handleDownload,
    handleAddCertificate,
  };
};

export default UseDoctorsCertificationsHooks;
