import { useEffect, useState } from "react";
import {
  Control,
  Controller,
  FieldValues,
  UseFormSetValue,
} from "react-hook-form";
import { ClipLoader } from "react-spinners";
import { IAvatar } from "../../services/interfaces";
import uploadImageToBunny from "../../utils/uploadImageToBunny";
import { downloadImageFromBunny } from "../../utils/downloadImgFromBunny";
import personImg from "../../assets/images/Portrait_Placeholder.png";
import cameraIcon from "../../assets/images/camera-svgrepo-com.svg";
import styles from "./imageUpload.module.scss";

interface IProps {
  control: Control<FieldValues, any>;
  controllerName: string;
  setValue: UseFormSetValue<FieldValues>;
  defaultValue: IAvatar | undefined;
}

const ImageUpload: React.FC<IProps> = (props) => {
  const { control, controllerName, setValue, defaultValue } = props;

  const [imgValue, setImgValue] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className={styles["upload-img__container"]}>
      <div className={styles["profile-img__container"]}>
        {loading ? (
          <div className={styles["profile-img__loader"]}>
            <ClipLoader color={"grey"} size={50} />
          </div>
        ) : (
          <img
            src={
              imgValue
                ? downloadImageFromBunny({ avatar: imgValue })
                : defaultValue
                ? downloadImageFromBunny({ avatar: defaultValue })
                : personImg
            }
            className={styles["profile-img"]}
          />
        )}
      </div>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <input
            style={{ display: "none" }}
            accept="image/*"
            onChange={(e: any) => {
              e.target.files && setLoading(true);
              const uploadFiles = async () => {
                try {
                  const result = await uploadImageToBunny({
                    files: Object.values(e.target.files),
                    dir: "profile",
                  });
                  if (result?.[0]) {
                    setLoading(false);
                  }
                  setValue("avatar", result?.[0]);
                  setImgValue(result?.[0]);
                  localStorage.setItem("fileType", "image");
                } catch (error) {
                  console.error("Upload failed:", error);
                }
              };
              uploadFiles();
            }}
            type="file"
            id="fileInput"
          />
        )}
        name={controllerName}
      />
      <img
        src={cameraIcon}
        alt="Upload Preview"
        onClick={() => document?.getElementById("fileInput")?.click()}
        style={{
          cursor: "pointer",
          objectFit: "cover",
          width: "30px",
          height: "30px",
        }}
        className={styles["camera-icon"]}
      />
    </div>
  );
};

export default ImageUpload;
