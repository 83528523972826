import { Control, Controller, FieldError, FieldErrors, FieldValues } from "react-hook-form";
import { MenuItem, OutlinedInput, Select } from "@mui/material";
import { IControllerRules, IMultiselectDataItem } from "../interfaces";
import styles from "./customMultiSelect.module.scss";

interface IProps {
  label: string;
  defaultValue?: any;
  controllerName: string;
  data: IMultiselectDataItem[];
  controllerRules: IControllerRules;
  errors?: FieldErrors<FieldValues>;
  control: Control<FieldValues, any>;
}

const CustomMultiSelect: React.FC<IProps> = (props) => {
  const {
    data,
    label,
    errors,
    control,
    defaultValue,
    controllerName,
    controllerRules,
  } = props;  

  return (
    <div className={styles["multiselect-container"]}>
      {label && <label className={styles["label"]}>{label}</label>}
      <Controller
        key={controllerName}
        control={control}
        name={controllerName}
        rules={controllerRules}
        render={({ field: { onChange, name, value = [] } }) => {
          return (
            <div className="multi-custom-select">
              <Select
                multiple
                displayEmpty
                fullWidth
                value={Array.isArray(value) ? value : []}
                onChange={onChange}
                input={<OutlinedInput />}
                defaultValue={defaultValue}
                renderValue={(selected) => {
                  const selectedLabels = selected
                    .map((selectedValue: any) => {
                      const selectedItem = data.find(
                        (item) => item.id === selectedValue
                      );
                      return selectedItem ? selectedItem.label : selectedValue;
                    })
                    .join(", ");
                  return selectedLabels;
                }}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#053D57",
                    boxShadow: "0 0 5px rgba(80, 91, 241, 1)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "lightblue",
                  },
                }}
              >
                {data?.map((item: IMultiselectDataItem, index: number) => {
                  return (
                    <MenuItem key={index} value={item?.id}>
                      {item?.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          );
        }}
      />
      {(errors?.[controllerName] as FieldError)?.message && (
        <label className={styles["input-error"]}>
          {(errors?.[controllerName] as FieldError).message}
        </label>
      )}
    </div>
  );
};

export default CustomMultiSelect;
