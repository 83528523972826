import { ClipLoader } from "react-spinners";
import noImageIcon from "../../assets/images/no-image.png";
import { ButtonTypeEnum } from "../../components/interfaces";
import UseReportsSinglePageHooks from "./reportsSinglePageHooks";
import {  downloadImageFromBunny,  downloadVideoFromBunny } from "../../utils/downloadImgFromBunny";
import {
  CustomButton,
  CustomModal,
  CustomSlider,
  ErrorComponent,
  HlsVideoPlayer,
  ReportReasonsList,
} from "../../components";
import UseCustomModalHooks from "../../components/CustomModal/customModal.hooks";
import styles from "./reportsSinglePage.module.scss";

const ReportsSinglePage: React.FC = () => {
  const { open, handleClose, handleClickOpen } = UseCustomModalHooks();
  const {
    reportsData,
    reportByIdInfo,
    reportByIdError,
    reportByIdLoading,
    reportReasonsList,
    deletePostLoading,
    deletePostReq,
  } = UseReportsSinglePageHooks({ handleClose });

  if (reportByIdLoading) {
    return (
      <div className="loader-page">
        <ClipLoader color={"grey"} size={50} />
      </div>
    );
  }

  if (reportByIdError) {
    return (
      <div className="loader-page">
        <ErrorComponent />
      </div>
    );
  }
  

  return (
    <div className="outlet-page">
      <h1 className="page-heading" style={{ marginBottom: "20px" }}>
        Բողոքարկման մանրամասները
      </h1>
      <div className={styles["report-container"]}>
        <div className={styles["report-info__container"]}>
          {reportsData?.map((item) => {
            return (
              <div className={styles["report-info__item"]} key={item?.id}>
                <div className={styles["label"]}>{item?.label}</div>
                <div className={styles["content"]}>{item?.content}</div>
              </div>
            );
          })}
        </div>
        <ReportReasonsList reportReasonsList={reportReasonsList} reportByIdInfo={reportByIdInfo}/>
      </div>
      <h1 className="page-heading" style={{ marginBottom: "20px" }}>
        Հրապարակումը, որին կցված է բողոքարկումը
      </h1>
      <div className={styles["post"]}>
        {reportByIdInfo?.result?.post?.media_content?.[0]?.type === "image" || reportByIdInfo?.result?.post?.parentPost?.media_content?.[0]?.type === "image" ? (
          reportByIdInfo?.result?.post?.media_content?.length > 1 || reportByIdInfo?.result?.post?.parentPost?.media_content?.length 
          > 1 ? (
            <CustomSlider
              imagesArr={reportByIdInfo?.result?.post?.media_content}
            />
          ) : (
            <img
              src={downloadImageFromBunny({
                avatar: reportByIdInfo?.result?.post?.media_content?.[0] ? reportByIdInfo?.result?.post?.media_content?.[0] : reportByIdInfo?.result?.post?.parentPost?.media_content?.[0],
              })}
              alt={"noImageIcon"}
              className={styles["post-img"]}
            />
          )
        ) : reportByIdInfo?.result?.post?.media_content?.[0]?.type ===
          "video" ? (
          <HlsVideoPlayer
            src={
              downloadVideoFromBunny({
                video: reportByIdInfo?.result?.post?.media_content?.[0],
              })!
            }
          />
        ) : (
          <img
            src={noImageIcon}
            alt={"noImageIcon"}
            className={styles["post-img"]}
          />
        )}
        <p className={styles["text-content"]}>
          {reportByIdInfo?.result?.post?.text_content
            ? reportByIdInfo?.result?.post?.text_content
            : "Տվյալ փոստը նկարագրություն չունի"}
        </p>
        <CustomButton
          buttonText={"Ջնջել փոստը"}
          className="delete-btn"
          type={ButtonTypeEnum.button}
          onClick={() => handleClickOpen()}
          loading={false}
        />
      </div>
      <CustomModal
        open={open}
        handleClose={handleClose}
        onConfirm={() => deletePostReq(reportByIdInfo?.result?.post?.id!)}
        loading={deletePostLoading}
        title={"Համոզվա՞ծ եք, որ ցանկանում եք ջնջել տվյալ հրապարակումը"}
      ></CustomModal>
    </div>
  );
};

export default ReportsSinglePage;
