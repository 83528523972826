import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { IReasonFormValues } from "../../components/interfaces";
import {
  useAddReasonMutation,
  useEditReasonMutation,
  useGetReasonByIdQuery,
  useGetReasonsListQuery,
} from "../../services/Requsts/reasonsApi";

interface IProps {
  handleClose: () => void;
}

const UseReasonHooks = (props: IProps) => {
  const [editReason, setEditReason] = useState<boolean>(false);
  const [reasonId, setReasonId] = useState<string>("");
  const [page, setPage] = useState<number>(1);

  const { data: reasonByIdData, isSuccess: reasonByIdSuccess } =
    useGetReasonByIdQuery(reasonId, { skip: !reasonId });

  const {
    data: reasonsList,
    isError: reasonsListError,
    isLoading: reasonsListLoading,
  } = useGetReasonsListQuery(page);

  const [
    editReasnReq,
    { isLoading: reasonsEditLoading, isSuccess: reasonsEditSuccess },
  ] = useEditReasonMutation();

  const [
    addReasonReq,
    { isSuccess: addedReasonSuccessfully, isLoading: addReasonLoading },
  ] = useAddReasonMutation();

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm<FieldValues | IReasonFormValues>();

  const onSubmit = (data: FieldValues) => {
    const payload = {
      tag: data?.tag,
      title: {
        en: data?.title_en,
        ru: data?.title_ru,
        am: data?.title_am,
      },
    };
    editReason
      ? editReasnReq({ payload, id: reasonId })
      : addReasonReq(payload);
  };

  useEffect(() => {
    if (addedReasonSuccessfully || reasonsEditSuccess) {
      toast.success("Գործողությունը հաջողությամբ կատարվել է");
      props?.handleClose();
    }
  }, [addedReasonSuccessfully, reasonsEditSuccess]);

  useEffect(() => {
    if (reasonByIdSuccess && reasonByIdData) {
      reset();
      setValue("tag", reasonByIdData?.result?.tag || "");
      setValue("title_en", reasonByIdData?.result?.title?.en || "");
      setValue("title_ru", reasonByIdData?.result?.title?.ru || "");
      setValue("title_am", reasonByIdData?.result?.title?.am || "");
    }
  }, [reasonByIdSuccess, reasonByIdData]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  return {
    reset,
    onSubmit,
    setReasonId,
    handleSubmit,
    setEditReason,
    handlePageChange,
    reasonsEditLoading,
    reasonsListLoading,
    addReasonLoading,
    reasonsListError,
    reasonsList,
    editReason,
    control,
    errors,
    page,
  };
};

export default UseReasonHooks;
