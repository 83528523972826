import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../API";

export const postsApi = createApi({
  reducerPath: "postsApi",
  tagTypes: ["posts"],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    deletePost: builder.mutation<number, string>({
      query(id) {
        return {
          url: `/post/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["posts"],
    }),
  }),
});

export const {
  useDeletePostMutation,
} = postsApi;