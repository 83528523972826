import { ClipLoader } from "react-spinners";
import { DoctorAdditionalInfoEdit, ErrorComponent, UserEdit } from "../../components";
import UseGetSingleDoctorHooks from "./doctorsSinglePage.hooks";

const DoctorsSinglePage: React.FC = () => {
  const { doctorSingleData, singleDoctorDataLoading, singleDoctorDataError } = UseGetSingleDoctorHooks();    

  if (singleDoctorDataLoading) {
    return (
      <div className="loader-page">
        <ClipLoader color={"grey"} size={50} />
      </div>
    );
  }

  if (singleDoctorDataError) {
    return (
      <div className="loader-page">
        <ErrorComponent />
      </div>
    );
  }

  return (
    <div className="outlet-page">
      <h1 className="page-heading">Բժիշկի անձնական ինֆորմացիա</h1>
      <UserEdit userSingleData={doctorSingleData} />
      <h1 className="page-heading">Հավելյալ տեղեկատվություն բժիշկի մասին</h1>
      <DoctorAdditionalInfoEdit doctorSingleData={doctorSingleData}/>
    </div>
  );
};

export default DoctorsSinglePage;
