import { IReason } from "../../services/interfaces";
import CustomButton from "../CustomButton/customButton";
import { ButtonTypeEnum } from "../interfaces";
import UseReasonsTableHooks from "./reasonsTableHooks";
import UseCustomModalHooks from "../CustomModal/customModal.hooks";
import CustomModal from "../CustomModal/CustomModal";

interface IProps {
  reasonsList: IReason[] | undefined;
  openEditModal: () => void,
  setEditReason: (val: boolean) => void,
  setReasonId: (val: string) => void,
  page: number
}

const ReasonsTable: React.FC<IProps> = (props) => {
  const { reasonsList, openEditModal, setEditReason, setReasonId, page } = props;
  const { open, handleClose, handleClickOpen } = UseCustomModalHooks();
  const {
    setSelectedReasonId,
    deleteReasonReq,
    selectedReasonId,
    deleteReasonLoading,
  } = UseReasonsTableHooks({handleClose});  

  return (
    <table className="table">
      <thead>
        <tr>
          <th>&#8470;</th>
          <th>Պատճառը</th>
          <th>Փոփոխել</th>
          <th>Ջնջել</th>
        </tr>
      </thead>
      <tbody>
        {reasonsList?.map((item: IReason, index: number) => {
          return (
            <tr key={index}>
              <td>{(page - 1) * 10 + index + 1}</td>
              <td>{item?.title?.en ? item?.title?.en : "---"}</td>
              <td>
                <CustomButton
                  buttonText="Փոփոխել"
                  type={ButtonTypeEnum.button}
                  onClick={() => {openEditModal(); setEditReason(true); setReasonId(item?.id)}}
                  loading={false}
                />
              </td>
              <td>
                <CustomButton
                  buttonText="Ջնջել"
                  type={ButtonTypeEnum.button}
                  onClick={() => {
                    setSelectedReasonId(item?.id);
                    handleClickOpen()
                  }}
                  loading={false}
                  className="delete-btn"
                  disabled={item?.
                    reports?.length > 0}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
      <CustomModal
        open={open}
        handleClose={handleClose}
        onConfirm={() => deleteReasonReq(selectedReasonId)}
        loading={deleteReasonLoading}
        title={"Համոզվա՞ծ եք, որ ցանկանում եք ջնջել տվյալ բողոքարկումը"}
      ></CustomModal>
    </table>
  );
};

export default ReasonsTable;
