import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../API";
import {
  IReportByIdResp,
  IReportsListResp,
  IReportReasonsList,
} from "../interfaces";

export const reportsApi = createApi({
  reducerPath: "reportsApi",
  tagTypes: ["reports"],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    getReportsList: builder.query<IReportsListResp, void>({
      query() {
        return {
          url: `/post/admin/report`,
          method: "GET",
        };
      },
      providesTags: ["reports"],
    }),
    getReportById: builder.query<IReportByIdResp, string>({
      query(id) {
        return {
          url: `/post/admin/report/${id}`,
          method: "GET",
        };
      },
      providesTags: ["reports"],
    }),
    getReportReasons: builder.query<IReportReasonsList, void>({
      query() {
        return {
          url: `/post/admin/reasons`,
          method: "GET",
        };
      },
      providesTags: ["reports"],
    }),
    deleteReport: builder.mutation<number, string>({
      query(id) {
        return {
          url: `/post/admin/report/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["reports"],
    }),
  }),
});

export const {
  useGetReportByIdQuery,
  useGetReportsListQuery,
  useDeleteReportMutation,
  useGetReportReasonsQuery,
} = reportsApi;
