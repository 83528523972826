import {
  CustomButton,
  CustomChechbox,
  CustomRadioButton,
} from "../index";
import UsePatientEditHooks from "./patientEdit.hooks";
import stepsData from "../../utils/patientData.json";
import { ButtonTypeEnum } from "../interfaces";
import { ISinglePatientDataResp } from "../../services/interfaces";
import styles from "./patientEdit.module.scss";

interface IProps {
  patientSingleData: ISinglePatientDataResp | undefined;
}

const PatientsEdit: React.FC<IProps> = (props) => {
  const {
    errors,
    control,
    isLoading,
    onSubmit,
    setValue,
    handleSubmit,
    handleCheckboxChange,
    getDefaultValueForField,
    handleOtherCheckboxChange,
  } = UsePatientEditHooks({ patientSingleData: props.patientSingleData });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className={styles["edit-patient__form"]}>
        {stepsData?.steps?.[0]?.questions?.map((item: any) => {
          const defaultValue = getDefaultValueForField(
            item,
            props.patientSingleData
          );
          if (item?.type === "radio") {
            return (
              <CustomRadioButton
                control={control}
                controllerName={item.key}
                label={item?.label}
                controllerRules={{ required: "Պարտադիր է" }}
                radioBtnData={item.options}
                defaultValue={defaultValue}
              />
            );
          }
          if (item?.type === "checkbox") {
            return (
              <CustomChechbox
                controllerName={item?.key}
                control={control}
                data={item?.options}
                defaultValue={defaultValue}
                errors={errors}
                controllerRules={{ required: "Պարտադիր է" }}
                label={item?.label}
                handleCheckboxChange={handleCheckboxChange}
                setValue={setValue}
                handleOtherCheckboxChange={handleOtherCheckboxChange}
              />
            );
          }
        })}
        {stepsData?.steps?.[1]?.questions?.map((item: any) => {
          const defaultValue = getDefaultValueForField(
            item,
            props.patientSingleData
          );
          if (item?.type === "radio") {
            return (
              <CustomRadioButton
                control={control}
                controllerName={item.key}
                label={item?.label}
                controllerRules={{ required: "Պարտադիր է" }}
                radioBtnData={item.options}
                defaultValue={defaultValue}
              />
            );
          }
          if (item?.type === "checkbox") {
            return (
              <CustomChechbox
                controllerName={item?.key}
                control={control}
                data={item?.options}
                defaultValue={defaultValue}
                errors={errors}
                controllerRules={{ required: "Պարտադիր է" }}
                label={item?.label}
                handleCheckboxChange={handleCheckboxChange}
                setValue={setValue}
                handleOtherCheckboxChange={handleOtherCheckboxChange}
              />
            );
          }
        })}
        {stepsData?.steps?.[2]?.questions?.map((item: any) => {
          const defaultValue = getDefaultValueForField(
            item,
            props.patientSingleData
          );
          if (item?.type === "radio") {
            return (
              <CustomRadioButton
                control={control}
                controllerName={item.key}
                label={item.label}
                controllerRules={{ required: "Պարտադիր է" }}
                radioBtnData={item.options}
                defaultValue={defaultValue}
              />
            );
          }
          if (item?.type === "checkbox") {
            return (
              <CustomChechbox
                controllerName={item?.key}
                control={control}
                data={item?.options}
                defaultValue={defaultValue}
                errors={errors}
                controllerRules={{ required: "Պարտադիր է" }}
                label={item?.label}
                handleCheckboxChange={handleCheckboxChange}
                setValue={setValue}
                handleOtherCheckboxChange={handleOtherCheckboxChange}
              />
            );
          }
        })}
        <CustomButton
          buttonText={"Փոփոխել"}
          type={ButtonTypeEnum.submit}
          loading={isLoading}
        />
      </form>
    </div>
  );
};

export default PatientsEdit;
