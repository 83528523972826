import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import {
  IControllerRules,
  IEditDoctorForm,
  InputTypeEnum,
} from "../../interfaces";
import {CustomInput} from "../../index";
import uploadFileImg from "../../../assets/images/file-upload-svgrepo-com.svg";
import addCircleIcon from "../../../assets/images/plus-circle-svgrepo-com.svg";
import UseDoctorsLicenseHooks from "./doctorsLicense.hooks";
import uploadImageToBunny from "../../../utils/uploadImageToBunny";
import styles from "./doctorsLicenses.module.scss";

interface IProps {
  errors: FieldErrors<FieldValues>;
  control: Control<FieldValues, any>;
  controllerRules: IControllerRules;
  licenseFields: Record<"id", string>[];
  setValue: UseFormSetValue<FieldValues | IEditDoctorForm>;
  getValues: UseFormGetValues<FieldValues | IEditDoctorForm>;
  watch: UseFormWatch<FieldValues | IEditDoctorForm>;
  removeLicense: UseFieldArrayRemove;
  addNewLicenseField: () => void;
}

const DoctorsLicenses: React.FC<IProps> = (props) => {
  const {
    errors,
    control,
    controllerRules,
    licenseFields,
    addNewLicenseField,
    removeLicense,
    getValues,
    watch,
  } = props;

  const { handleDownload, handleAddLicense } = UseDoctorsLicenseHooks();

  return (
    <div className={styles["license-item__container"]}>
      {licenseFields?.map((field, index) => {
        const watchedFile = watch(`license.${index}.file`);
        return (
          <div key={field?.id} className={styles["license-item"]}>
            <img
              className={styles["uploadFileImg"]}
              src={uploadFileImg}
              onClick={() => handleAddLicense(index)}
            />
            {watchedFile?.name ? (
              <span>
                {watchedFile?.name?.slice(0, 15)}...
              </span>
            ) : (
              <span>Ընտրված ֆայլ չկա</span>
            )}
            <Controller
              key={`license.${index}.file`}
              control={control}
              name={`license.${index}.file`}
              rules={controllerRules}
              render={({ field: { onChange, name, value } }) => {
                return (
                  <input
                    id={`fileUpload-${index}`}
                    type="file"
                    style={{ display: "none" }}
                    onChange={async (
                      e: React.ChangeEvent<HTMLInputElement>
                    ) => {
                      try {
                        const filesArray = Array.from(e.target.files || []);
                        localStorage.setItem("fileType", "file");
                        await uploadImageToBunny({
                          files: filesArray,
                          dir: "files",
                        }).then((resp) => {
                          onChange(resp?.[0]);
                        });
                      } catch (error) {
                        console.error("Upload failed:", error);
                      }
                    }}
                  />
                );
              }}
            />
            <CustomInput
              type={InputTypeEnum.number}
              controllerName={`license.${index}.licenseNumber`}
              control={control}
              controllerRules={{
                required: false,
              }}
              label="Լիցենզիայի համար"
              errors={errors}
            />
            <div className={styles["license-actions"]}>
              <span
                className={styles["download-licence"]}
                onClick={() =>
                  handleDownload(getValues()?.license?.[index]?.file)
                }
              >
                Ներբեռնել
              </span>
              <span
                className={styles["delete-licence"]}
                onClick={() => removeLicense(index)}
              >
                Ջնջել
              </span>
            </div>
          </div>
        );
      })}
      <div className={styles["license-item"]}>
        <img src={addCircleIcon} onClick={addNewLicenseField} />
        <span className={styles["add-certification"]}>Ավելացնել</span>
      </div>
    </div>
  );
};

export default DoctorsLicenses;
