import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../API";
import { IDoctorsListPayload, IEditDoctorPayload, IEditDoctorUserResp, ISingleDoctorInfoResp, IUsersListResp } from "../interfaces";
import { ExperienceEnum, StatusesEnum } from "../../components/interfaces";

export const doctorsApi = createApi({
  reducerPath: "doctorsApi",
  tagTypes: ["doctors", "users"],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    getDoctorsList: builder.query<IUsersListResp, IDoctorsListPayload>({
      query({page, filter, search}) {
        return {
          url: `/user/admin/doctor?limit=10&page=${page}&filter=${filter}${search ? `&search=${search}` : ''}`,
          method: "GET",
        };
      },
      providesTags: ["doctors", "users"],
    }),
    getSingleDoctorInfo: builder.query<ISingleDoctorInfoResp, string | undefined>({
      query(id) {
        return {
          url: `/user/admin/doctor/${id}`,
          method: "GET",
        };
      },
      providesTags: ["doctors"],
    }),
    deleteDoctor: builder.mutation<IEditDoctorUserResp, string>({
      query(id) {
        return {
          url: `/user/admin/doctor/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["doctors"],
    }),
    editDoctor: builder.mutation<any, IEditDoctorPayload>({
      query({ id, payload }) {
        return {
          url: `/user/admin/doctor/${id}`,
          method: "PUT",
          data: payload,
        };
      },
      invalidatesTags: ["doctors"],
    }),
  }),
});

export const {
  useGetDoctorsListQuery,
  useGetSingleDoctorInfoQuery,
  useDeleteDoctorMutation,
  useEditDoctorMutation,
} = doctorsApi;
