import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormHandleSubmit,
  UseFormSetValue,
} from "react-hook-form";
import { AxiosRequestConfig } from "axios";
import { QueryDefinition } from "@reduxjs/toolkit/query";
import { BaseQueryFn, QueryActionCreatorResult } from "@reduxjs/toolkit/query";
import { ButtonTypeEnum, DoctorFiltrationValues, IOption } from "../interfaces";
import { IDoctorsListPayload, IUsersListResp } from "../../services/interfaces";
import { clinics, experienceData } from "../../utils/generalData";
import { CustomSelect } from "../index";
import { CustomButton } from "../index";
import { CustomMultiSelect } from "../index";
import styles from "./doctorsFiltration.module.scss";

interface IProps {
  handleSubmit: UseFormHandleSubmit<
    FieldValues | DoctorFiltrationValues,
    undefined
  >;
  onSubmit: (data: FieldValues | DoctorFiltrationValues) => void;
  control: Control<FieldValues | DoctorFiltrationValues, any>;
  errors: FieldErrors<FieldValues | DoctorFiltrationValues>;
  filteredSpecializations: IOption[];
  loading: boolean;
  filteredGoals: IOption[];
  setFormValue: UseFormSetValue<FieldValues | DoctorFiltrationValues>,
  refetch: () => QueryActionCreatorResult<QueryDefinition<IDoctorsListPayload, BaseQueryFn<{
    url: string;
    method: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
}, unknown, unknown>, "doctors" | "users", IUsersListResp, "doctorsApi">>
}

const DoctorsFiltration: React.FC<IProps> = (props) => {
  const {
    setFormValue,
    handleSubmit,
    onSubmit,
    refetch,
    control,
    errors,
    loading,
    filteredGoals,
    filteredSpecializations,
  } = props;
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles["doctor-filtration__form"]}
    >
      <CustomSelect
        controllerName="specialization"
        control={control}
        controllerRules={{ required: false }}
        label="Մասնագիտացումը"
        data={filteredSpecializations}
        errors={errors}
      />
      <CustomMultiSelect
        controllerName="clinics"
        control={control}
        controllerRules={{ required: false }}
        label="Կլինիկաներ"
        data={clinics}
        errors={errors}
      />
      <CustomSelect
        controllerName="experience"
        control={control}
        controllerRules={{ required: false }}
        label="Փորձ"
        data={experienceData}
        errors={errors}
      />
      <CustomMultiSelect
        controllerName="app_goals"
        control={control}
        controllerRules={{ required: false }}
        label="Նպատակները"
        data={filteredGoals}
        errors={errors}
      />
      <CustomButton
        buttonText={"Կիրառել"}
        type={ButtonTypeEnum.submit}
        onClick={() => {}}
        loading={loading}
      />
      <CustomButton
        buttonText={"Չեղարկել"}
        type={ButtonTypeEnum.button}
        onClick={() => {
          setFormValue("clinics", [])
          setFormValue("specialization", {})
          setFormValue("experience", {})
          setFormValue("app_goals", []);
          refetch()
        }}
        loading={loading}
        className="delete-btn"
      />
    </form>
  );
};

export default DoctorsFiltration;
