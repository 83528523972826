import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { FieldValues, useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ISingleDoctorInfoResp, ISinglePatientDataResp } from "../../services/interfaces";
import { useGetPatientsListQuery } from "../../services/Requsts/patientApi";
import { useGetDoctorsListQuery } from "../../services/Requsts/doctorsApi";
import { useEditUserMutation } from "../../services/Requsts/userApi";
import { IDoctorUserEditFormValues } from "../interfaces";

const phones: any = {
  'am': /^(\+?374|0)(10|[9|7][0-9])\d{6}$/,
  'ar': /^((\+?971)|0)?5[024568]\d{7}$/,
  'be': /^(\+?375)?(24|25|29|33|44)\d{7}$/,
  'bg': /^(\+?359|0)?8[789]\d{7}$/,
  'bn': /^(\+?880|0)1[13456789]\d{8}$/,
  'cs': /^(\+?420)?[1-9]\d{2} ?\d{3} ?\d{3}$/,
  'da': /^(\+?45)?\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2}$/,
  'de': /^(\+49|0)?1[567]\d{8}$/,
  'el': /^(\+?30|0)?69\d{8}$/,
  'en': /^(\+1|1)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
  'es': /^(\+?56|0)[2-9]\d{1}\d{7}$/,
  'et': /^(\+?372)?(5|8[1-4])\d{6,7}$/,
  'fa': /^(\+?98|0)?9[0-39]\d{8}$/,
  'fi': /^(\+?358|0)?(4\d|50)\d{4,8}$/,
  'fj': /^(\+?679)?\d{3}\d{4}$/,
  'fo': /^(\+?298)?\d{6}$/,
  'fr': /^(\+?33|0)[67]\d{8}$/,
  'he': /^(\+?972|0)?5\d{8}$/,
  'hu': /^(\+?36)(20|30|70)\d{7}$/,
  'id': /^(\+?62|0)8\d{8,12}$/,
  'it': /^(\+?39)?3\d{2}\d{6,7}$/,
  'ja': /^(\+81|0)?[789]0\d{8}$/,
  'kk': /^(\+?7|8)7\d{9}$/,
  'kl': /^(\+?299)?\d{6}$/,
  'ko': /^(\+?82)?01\d{8}$/,
  'lt': /^(\+370|8)\d{8}$/,
  'ms': /^(\+?6?01)\d{7,8}$/,
  'nb': /^(\+?47)?[49]\d{7}$/,
  'ne': /^(\+?977)?9[78]\d{8}$/,
  'nl': /^(\+?32|0)4\d{8}$/,
  'nn': /^(\+?47)?[49]\d{7}$/,
  'pl': /^(\+?48)?[5-8]\d{8}$/,
  'pt': /^(\+?55)?\d{10,11}$/,
  'ro': /^(\+?4?0)\s?7\d{2}[-.\s]?\d{3}[-.\s]?\d{3}$/,
  'ru': /^(\+?7|8)?\d{10}$/,
  'sl': /^(\+386|0)?[1-7]\d{7,8}$/,
  'sk': /^(\+?421)?[1-9]\d{2} ?\d{3} ?\d{3}$/,
  'sr': /^(\+381|0)6\d{7,8}$/,
  'sv': /^(\+?46|0)7\d{8}$/,
  'th': /^(\+?66|0)\d{9}$/,
  'tr': /^(\+?90|0)?5\d{9}$/,
  'uk': /^(\+?38|8)?0\d{9}$/,
  'vi': /^(\+?84|0)(3[2-9]|5[6|8|9]|7[0|6-9]|8[1-9]|9\d)\d{7}$/,
  'zh': /^(\+?86|0)?1[3-9]\d{9}$/,
};

interface IProps {
  userSingleData: ISingleDoctorInfoResp | ISinglePatientDataResp | undefined;
}

const UseUserHooks = (props: IProps) => {
  const [editDoctorUser, { isLoading, isSuccess }] = useEditUserMutation();
  const [code, setCode] = useState('am');

  const phoneValidationSchema = useMemo(()=>Yup.object().shape({
    phone_number: Yup.string()
      .required('Հեռախոսահամարը պարտադիր է')
      .test('is-valid-phone', 'Անվավեր հեռախոսահամար', function (value) {
        const countryIso = this.options.context?.countryIso as any; 
        if (!value) return false;
        if (!countryIso || !phones[countryIso]) return true;
        const regex = new RegExp(phones[countryIso]);
        return regex.test(value);
      }),
  }),[]);


  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    setError
  } = useForm<FieldValues | IDoctorUserEditFormValues>({
    // @ts-ignore
    resolver: yupResolver(phoneValidationSchema),
    context: { countryIso: code },
    reValidateMode: "onChange",
    defaultValues:{
      phone_number:''
    }
  });

  const onSubmit = (data: FieldValues | IDoctorUserEditFormValues) => {
    const payload = {
      bio: data?.bio,
      avatar: data?.avatar,
      birth_day: data?.birth_day,
      last_name: data?.last_name,
      first_name: data?.first_name,
      phone_number: data?.phone_number,
    }
    editDoctorUser({
      id: props?.userSingleData?.result?.user?.id,
      payload: payload,
    });
  };

  const { refetch: refetchDoctors } = useGetDoctorsListQuery({page: 1, filter: JSON.stringify({status: props?.userSingleData?.result?.user?.status})}, {
    skip: !isSuccess,
  });
  
  const { refetch: refetchPatients } = useGetPatientsListQuery({page: 1, filter: JSON.stringify({status: props?.userSingleData?.result?.user?.status})}, {
    skip: !isSuccess,
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success("Բժշկի անձնական ինֆորմացիան հաջողությամբ թարմացվել է");
      refetchDoctors();
      refetchPatients();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (props?.userSingleData) {
      setValue("avatar", props?.userSingleData?.result?.user?.avatar);
      setValue("bio", props?.userSingleData?.result?.user?.bio);
      setValue("first_name", props?.userSingleData?.result?.user?.first_name);
      setValue("last_name", props?.userSingleData?.result?.user?.last_name);
      setValue("birth_day", props?.userSingleData?.result?.user?.birth_day && props?.userSingleData?.result?.user?.birth_day);
      setValue("phone_number", props?.userSingleData?.result?.user?.phone_number ? props?.userSingleData?.result?.user?.phone_number : '');
      setValue("business_phone_number", props?.userSingleData?.result?.business_phone ? props?.userSingleData?.result?.business_phone : '');
      setValue("business_email", props?.userSingleData?.result?.business_email ? props?.userSingleData?.result?.business_email : '')
    }
  }, [props?.userSingleData]);  

  return {
    code,
    errors,
    control,
    isLoading,
    handleSubmit,
    onSubmit,
    setValue,
    setError,
    setCode,
    watch,
  };
};

export default UseUserHooks;
