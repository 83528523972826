import { useSelector } from "react-redux";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormSetValue,
} from "react-hook-form";
import { AxiosRequestConfig } from "axios";
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { QueryActionCreatorResult, QueryDefinition } from "@reduxjs/toolkit/query";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { RootState } from "../../services/store";
import steps from "../../utils/patientData.json";
import UseGeneralHooks from "../../utils/generalHooks";
import { CustomButton, CustomChechbox, CustomSelect } from "../index";
import { ButtonTypeEnum, IEditPatientInfoValues, IOption } from "../interfaces";
import { IGetPatientsPayload, IPatientsFilter, IUsersListResp } from "../../services/interfaces";
import { resetPatientsFilters } from "../../services/Slices/patientsFilterSlice";
import resetFilter from "../../assets/images/close-md-svgrepo-com.svg";
import filterIcon from "../../assets/images/filter-svgrepo-com.svg";
import styles from "./patientsFiltration.module.scss";

interface IProps {
  handleSubmit: UseFormHandleSubmit<
    FieldValues | IEditPatientInfoValues,
    undefined
  >;
  onSubmit: (data: FieldValues | IEditPatientInfoValues) => void;
  control: Control<FieldValues | IEditPatientInfoValues, any>;
  errors: FieldErrors<FieldValues | IEditPatientInfoValues>;
  loading: boolean;
  setValue: UseFormSetValue<FieldValues | IEditPatientInfoValues>;
  handleCheckboxChange: (
    onChange: any,
    selectedValues: any[],
    item: IOption
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  openDrawer: boolean;
  setOpenDrawer: (val: boolean) => void;
  reset: UseFormReset<IEditPatientInfoValues | FieldValues>;
  reduxValues: IPatientsFilter;
  refetch: () => QueryActionCreatorResult<QueryDefinition<IGetPatientsPayload, BaseQueryFn<{
    url: string;
    method: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
}, unknown, unknown>, "patients", IUsersListResp, "patientsApi">>;
}

const PatientsFiltration: React.FC<IProps> = (props) => {
  const {
    handleCheckboxChange,
    handleSubmit,
    setValue,
    onSubmit,
    loading,
    control,
    errors,
    reset,
    openDrawer,
    setOpenDrawer,
    reduxValues,
    refetch,
  } = props;

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpenDrawer(newOpen);
  };

  const { dispatch } = UseGeneralHooks();

  const patientsFilter = useSelector(
    (state: RootState) => state.patientsFilter
  );

  const handleReset = () => {
    reset({
      affected_arm: "",
      dominant_arm: "",
      amputation_type: "",
      prosthetic_type: "",
      prosthetic_brand: "",
      medical_history: "",
      device_fit: "",
      device_satisfaction: "",
      device_challenges: "",
      training_needs: "",
      app_goals: "",
    });
    dispatch(resetPatientsFilters());
    refetch();
  };

  return (
    <div
      className={`${styles["patients-filtration__section"]} patients-filtration__section`}
    >
      <div>
        <Button onClick={toggleDrawer(true)}>
          Կիրառել ֆիլտրեր{" "}
          <img src={filterIcon} className={styles["filter-icon"]} />
        </Button>
        <Button onClick={handleReset}>
          Չեղարկել ֆիլտրերը{" "}
          <img src={resetFilter} className={styles["filter-icon"]} />
        </Button>
      </div>

      <Drawer
        open={openDrawer}
        anchor={"right"}
        onClose={toggleDrawer(false)}
        sx={{
          ".MuiPaper-root": {
            borderTopRightRadius: "0",
            borderBottomRightRadius: "0",
            width: "50%",
          },
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles["patient-filtration__form"]}
        >
          <div className={styles["select-container"]}>
            <CustomSelect
              controllerName="affected_arm"
              control={control}
              controllerRules={{ required: false }}
              label={steps?.steps?.[0]?.questions?.[0]?.title}
              data={steps?.steps?.[0]?.questions?.[0]?.options}
              errors={errors}
            />
            <CustomSelect
              controllerName="dominant_arm"
              control={control}
              controllerRules={{ required: false }}
              label={steps?.steps?.[0]?.questions?.[1]?.title}
              data={steps?.steps?.[0]?.questions?.[1]?.options}
              errors={errors}
            />
          </div>
          <div className={styles["checkbox-container"]}>
            <CustomChechbox
              controllerName={"amputation_type"}
              control={control}
              label={steps?.steps?.[0]?.questions?.[2]?.title}
              data={steps?.steps?.[0]?.questions?.[2]?.options}
              errors={errors}
              controllerRules={{ required: false }}
              handleCheckboxChange={handleCheckboxChange}
              setValue={setValue}
              defaultValue={reduxValues.amputation_type}
            />
            <CustomChechbox
              controllerName="training_needs"
              control={control}
              label={steps?.steps?.[2]?.questions?.[1]?.title}
              data={steps?.steps?.[2]?.questions?.[1]?.options}
              errors={errors}
              controllerRules={{ required: false }}
              handleCheckboxChange={handleCheckboxChange}
              setValue={setValue}
              defaultValue={reduxValues.training_needs}
            />
            <CustomChechbox
              controllerName="prosthetic_type"
              control={control}
              label={steps?.steps?.[0]?.questions?.[3]?.title}
              data={steps?.steps?.[0]?.questions?.[3]?.options}
              errors={errors}
              controllerRules={{ required: false }}
              handleCheckboxChange={handleCheckboxChange}
              setValue={setValue}
              horizontal
              defaultValue={reduxValues.prosthetic_type}
            />
          </div>
          <div className={styles["select-container"]}>
            <CustomSelect
              controllerName="medical_history"
              control={control}
              controllerRules={{ required: false }}
              label={steps?.steps?.[1]?.questions?.[0]?.title}
              data={steps?.steps?.[1]?.questions?.[0]?.options}
              errors={errors}
            />
            <CustomSelect
              controllerName="device_fit"
              control={control}
              controllerRules={{ required: false }}
              label={steps?.steps?.[1]?.questions?.[1]?.title}
              data={steps?.steps?.[1]?.questions?.[1]?.options}
              errors={errors}
            />
            <CustomSelect
              controllerName="device_satisfaction"
              control={control}
              controllerRules={{ required: false }}
              label={steps?.steps?.[1]?.questions?.[2]?.title}
              data={steps?.steps?.[1]?.questions?.[2]?.options}
              errors={errors}
            />
          </div>
          <div className={styles["checkbox-container"]}>
            <CustomChechbox
              controllerName="app_goals"
              control={control}
              label={steps?.steps?.[2]?.questions?.[2]?.title}
              data={steps?.steps?.[2]?.questions?.[2]?.options}
              errors={errors}
              controllerRules={{ required: false }}
              handleCheckboxChange={handleCheckboxChange}
              setValue={setValue}
              defaultValue={reduxValues.app_goals}
            />
            <CustomChechbox
              controllerName="device_challenges"
              control={control}
              label={steps?.steps?.[2]?.questions?.[0]?.title}
              data={steps?.steps?.[2]?.questions?.[0]?.options}
              errors={errors}
              controllerRules={{ required: false }}
              handleCheckboxChange={handleCheckboxChange}
              setValue={setValue}
              defaultValue={reduxValues.device_challenges}
            />
          </div>
          <div className={styles["checkbox-container"]}>
            <CustomChechbox
              controllerName="prosthetic_brand"
              control={control}
              label={steps?.steps?.[0]?.questions?.[4]?.title}
              data={steps?.steps?.[0]?.questions?.[4]?.options}
              errors={errors}
              controllerRules={{ required: false }}
              handleCheckboxChange={handleCheckboxChange}
              setValue={setValue}
              horizontal
              defaultValue={reduxValues.prosthetic_brand}
            />
          </div>
          <div className={styles["apply-button"]}>
            <CustomButton
              buttonText={"Կիրառել"}
              type={ButtonTypeEnum.submit}
              onClick={() => {}}
              loading={loading}
            />
            <CustomButton
              buttonText={"Փակել"}
              className="delete-btn"
              type={ButtonTypeEnum.button}
              onClick={toggleDrawer(false)}
              loading={false}
            />
          </div>
        </form>
      </Drawer>
    </div>
  );
};

export default PatientsFiltration;
