import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import MainNavigation from './routers/mainNavigation';
import { store } from './services/store';
import './App.scss';


function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <MainNavigation />
      </Provider>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
