import { useEffect, useState } from "react";
import { Control, Controller, FieldError, FieldErrors, FieldValues } from "react-hook-form";
import { ButtonTypeEnum } from "../../interfaces";
import {CustomButton} from "../../index";
import { MuiOtpInput } from "mui-one-time-password-input";
import styles from "./otpForm.module.scss";

interface IProps {
  control: Control<FieldValues, any>;
  errors?: FieldErrors<FieldValues>;
  loading: boolean;
  resentOtp: () => void;
}

const OTPForm: React.FC<IProps> = (props) => {
  const { control, errors, loading, resentOtp } = props;

  const [isCountingDown, setIsCountingDown] = useState(false);
  const [seconds, setSeconds] = useState(60);

  const startCountdown = () => {
    setIsCountingDown(true);
    setSeconds(60);
  };

  useEffect(() => {
    let countdownInterval: any;

    if (isCountingDown && seconds > 0) {
      countdownInterval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (seconds === 0) {
      setIsCountingDown(false);
    }

    return () => clearInterval(countdownInterval);
  }, [isCountingDown, seconds]);

  return (
    <>
      <div style={{ marginBottom: "15px" }}>
        <Controller
          key={"otp"}
          control={control}
          name={"otp"}
          rules={{
            required: "Պարտադիր է",
            pattern: {
              value: /^\d+$/,
              message: "Մուտքագրել միայն թվեր",
            },
          }}
          render={({ field: { onChange, name, value } }) => {
            return (
              <MuiOtpInput
                value={value}
                onChange={onChange}
                length={5}
                autoFocus
                className={"otp-input"}
              />
            );
          }}
        />
      </div>
      {(errors?.otp as FieldError)?.message && (
        <label className={styles["otp-error"]}>
          {(errors?.otp as FieldError).message}
        </label>
      )}
      <div className={styles["resend-code"]}>
        Դեռ չե՞ք ստացել կոդը։{" "}
        {isCountingDown ? (
          <span className={styles["resend-btn"]}>{`0${Math.floor(
            seconds / 60
          )} : ${String(seconds % 60).padStart(2, "0")}`}</span>
        ) : (
          <label
            className={styles["resend-btn"]}
            onClick={() => {
              resentOtp();
              !isCountingDown && startCountdown();
            }}
          >
            Ուղարկել կրկին
          </label>
        )}
      </div>
      <CustomButton
        buttonText={"Հաստատել"}
        type={ButtonTypeEnum.submit}
        onClick={() => {}}
        loading={loading}
      />
    </>
  );
};

export default OTPForm;
