import { useEffect } from "react";
import { LoginForm, OTPForm } from "../../components";
import UseLoginFormHooks from "../../components/Login/login.hooks";
import styles from "./logn.module.scss";
import UseGeneralHooks from "../../utils/generalHooks";

const LoginPage: React.FC = () => {
  const {
    handleSubmit,
    errors,
    onSubmit,
    control,
    next,
    sendEmail,
    signinLoading,
    verifyOtpLoading,
  } = UseLoginFormHooks();  

  return (
    <div className={styles["login-page"]}>
      <div className={styles["form-container"]}>
        <div className={styles["title"]}>Բարի գալուստ ադմին պանել</div>
        <form className={styles["form"]} onSubmit={handleSubmit(onSubmit)}>
          {next ? (
            <OTPForm
              control={control}
              loading={verifyOtpLoading}
              errors={errors}
              resentOtp={sendEmail}
            />
          ) : (
            <LoginForm
              control={control}
              loading={signinLoading}
              errors={errors}
              sendEmail={sendEmail}
            />
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
