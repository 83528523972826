import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDeleteReasonMutation } from "../../services/Requsts/reasonsApi";

interface IProps {
    handleClose: () => void
}

const UseReasonsTableHooks = (props: IProps) => {
  const [selectedReasonId, setSelectedReasonId] = useState<string>("");

  const [
    deleteReasonReq,
    { isSuccess: deletedReasonSuccessfully, isLoading: deleteReasonLoading },
  ] = useDeleteReasonMutation();

  useEffect(() => {
    if (deletedReasonSuccessfully) {
      toast.success("Բողոքարկման պատճառը հաջողությամբ ջնջվել է");
      props?.handleClose()
    }
  }, [deletedReasonSuccessfully]);

  return {
    setSelectedReasonId,
    deleteReasonReq,
    selectedReasonId,
    deleteReasonLoading,
  };
};

export default UseReasonsTableHooks;
