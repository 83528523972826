import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import patientsFilterSlice from "./Slices/patientsFilterSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import { patientsApi } from "./Requsts/patientApi";
import { configureStore } from "@reduxjs/toolkit";
import { doctorsApi } from "./Requsts/doctorsApi";
import { reportsApi } from "./Requsts/reportsApi";
import { reasonsApi } from "./Requsts/reasonsApi";
import { usersApi } from "./Requsts/userApi";
import { authApi } from "./Requsts/authApi";
import { postsApi } from "./Requsts/postApi";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [doctorsApi.reducerPath]: doctorsApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [reasonsApi.reducerPath]: reasonsApi.reducer,
    [patientsApi.reducerPath]: patientsApi.reducer,
    [postsApi.reducerPath]: postsApi.reducer,
    patientsFilter: patientsFilterSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      usersApi.middleware,
      reportsApi.middleware,
      reasonsApi.middleware,
      doctorsApi.middleware,
      patientsApi.middleware,
      postsApi.middleware
    ]),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
setupListeners(store.dispatch);
