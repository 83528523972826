import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useGetPatientsListQuery } from "../../services/Requsts/patientApi";
import {
  IEditPatientInfoValues,
  IOption,
  StatusesEnum,
} from "../../components/interfaces";
import { useChangeUserStatusMutation } from "../../services/Requsts/userApi";
import { RootState, useAppSelector } from "../../services/store";
import UseGeneralHooks from "../../utils/generalHooks";
import { savePatientsFilters } from "../../services/Slices/patientsFilterSlice";

interface IProps {
  handleClose: () => void;
  setOpenDrawer: (val: boolean) => void;
}

const UsePatientHooks = (props: IProps) => {
  const { dispatch } = UseGeneralHooks();
  const [page, setPage] = useState<number>(1);
  const [status, setStatus] = useState<StatusesEnum>(StatusesEnum?.ACTIVE);
  const [value, setValue] = useState<number>(0);
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const reduxValues = useAppSelector(
    (state: RootState) => state?.patientsFilter?.patientsFilter
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue: setFormValue,
    getValues,
    reset,
  } = useForm<FieldValues | IEditPatientInfoValues>({
    defaultValues: {
      affected_arm: reduxValues?.affected_arm,
      dominant_arm: reduxValues?.dominant_arm,
      amputation_type: reduxValues?.amputation_type,
      prosthetic_type: reduxValues?.prosthetic_type,
      prosthetic_brand: reduxValues?.prosthetic_brand,
      medical_history: reduxValues?.medical_history,
      device_fit: reduxValues?.device_fit,
      device_satisfaction: reduxValues?.device_satisfaction,
      device_challenges: reduxValues?.device_challenges,
      training_needs: reduxValues?.training_needs,
      app_goals: reduxValues?.app_goals,
    },
  });

  const onSubmit = (data: FieldValues | IEditPatientInfoValues) => {
    console.log(data, "datadatadatadata");
  };

  const formValues = getValues();

  const filter = {
    status: status,
    ...(formValues.affected_arm
      ? { affected_arm: formValues.affected_arm }
      : reduxValues.affected_arm && { affected_arm: reduxValues.affected_arm }),

    ...(formValues.dominant_arm
      ? { dominant_arm: formValues.dominant_arm }
      : reduxValues.dominant_arm && { dominant_arm: reduxValues.dominant_arm }),

    ...(formValues.amputation_type
      ? {
          amputation_type: formValues.amputation_type?.map((item: IOption) => {
            return { id: item?.id };
          }),
        }
      : reduxValues.amputation_type && {
          amputation_type: reduxValues.amputation_type,
        }),

    ...(formValues.training_needs
      ? {
          training_needs: formValues.training_needs?.map((item: IOption) => {return {id: item?.id}}),
        }
      : reduxValues.training_needs && {
          training_needs: reduxValues.training_needs,
        }),

    ...(formValues.prosthetic_type
      ? {
          prosthetic_type: formValues.prosthetic_type?.map((item: IOption) => {return {id: item?.id}}),
        }
      : reduxValues.prosthetic_type && {
          prosthetic_type: reduxValues.prosthetic_type,
        }),

    ...(formValues.medical_history
      ? { medical_history: formValues.medical_history }
      : reduxValues.medical_history && {
          medical_history: reduxValues.medical_history,
        }),
    ...(formValues.device_fit
      ? { device_fit: formValues.device_fit }
      : reduxValues.device_fit && { device_fit: reduxValues.device_fit }),

    ...(formValues.device_satisfaction
      ? { device_satisfaction: formValues.device_satisfaction }
      : reduxValues.device_satisfaction && {
          device_satisfaction: reduxValues.device_satisfaction,
        }),

    ...(formValues.app_goals
      ? {
          app_goals: formValues.app_goals?.map((item: IOption) => {return {id: item?.id}}),
        }
      : reduxValues.app_goals && { app_goals: reduxValues.app_goals }),

    ...(formValues.device_challenges
      ? {
          device_challenges: formValues.device_challenges?.map((item: IOption) => {return {id: item?.id}}),
        }
      : reduxValues.device_challenges && {
          device_challenges: reduxValues.device_challenges,
        }),

    ...(formValues.prosthetic_brand
      ? {
          prosthetic_brand: formValues.prosthetic_brand?.map((item: IOption) => {return {id: item?.id}}),
        }
      : reduxValues.prosthetic_brand && {
          prosthetic_brand: reduxValues.prosthetic_brand,
        }),
  };

  const {
    data: patientsList,
    refetch,
    isLoading: getPatientsListLoading,
    isSuccess,
    isError: getPatientsListError,
  } = useGetPatientsListQuery({ page, filter: JSON.stringify(filter) });

  const [
    changePatientStatusReq,
    {
      isLoading: changePatientStatusLoading,
      isSuccess: changePatientStatusSuccess,
    },
  ] = useChangeUserStatusMutation();

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  useEffect(() => {
    if (changePatientStatusSuccess) {
      props?.handleClose();
      toast.success("Տվյալ բժիշկի կարգավիճակը հաջողությամբ փոխված է");
      refetch();
    }
  }, [changePatientStatusSuccess]);

  useEffect(() => {
    if (isSuccess) {
      props?.setOpenDrawer(false);
      dispatch(savePatientsFilters(filter));
    }
  }, [isSuccess]);

  useEffect(() => {
    refetch;
  }, [status]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      setStatus(StatusesEnum?.ACTIVE);
    } else if (newValue === 1) {
      setStatus(StatusesEnum?.PENDING);
    } else if (newValue === 2) {
      setStatus(StatusesEnum?.DELETED);
    }
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleCheckboxChange = (
    onChange: any,
    selectedValues: any[],
    item: IOption
  ) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      let updatedValues;

      if (isChecked) {
        updatedValues = [...selectedValues, item];
      } else {
        updatedValues = selectedValues.filter((value) => value.id !== item.id);
      }
      onChange(updatedValues);
    };
  };

  return {
    page,
    value,
    status,
    errors,
    control,
    reduxValues,
    patientsList,
    getPatientsListError,
    getPatientsListLoading,
    changePatientStatusLoading,
    changePatientStatusSuccess,
    changePatientStatusReq,
    handleCheckboxChange,
    setSearchInputValue,
    handlePageChange,
    setFormValue,
    handleSubmit,
    handleChange,
    a11yProps,
    onSubmit,
    refetch,
    reset,
    open,
  };
};

export default UsePatientHooks;
