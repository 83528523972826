import {
  IAvatar,
  DominantArmEnum,
  IPatentInfoItem,
  DeviceChallangesEnum,
  DeviceSatisfactionEnum,
} from "../services/interfaces";

// enums

export enum InputTypeEnum {
  text = "text",
  password = "password",
  email = "email",
  number = "number",
}

export enum ButtonTypeEnum {
  button = "button",
  reset = "reset",
  submit = "submit",
}

export enum StatusesEnum {
  PENDING = "pending",
  ACTIVE = "active",
  BLOCKED = "blocked",
  DELETED = "deleted",
}

export enum ExperienceEnum {
  YEARS_1_5 = "1_5_years",
  YEARS_6_10 = "6_10_years",
  YEARS_11_15 = "11_15_years",
  YEARS_16_20 = "16_20_years",
  YEARS_NORE_THAN_20 = "more_than_20_years",
}

export enum PagesEnum {
  DOCTORS = "doctors",
  PATIENTS = "patients",
}

export enum MedicalHistoryEnum {
  YES = "yes",
  NO = "no",
}

export enum DeviceFitEnum {
  YES = "yes",
  NO = "no",
  NOT_SURE = "not_sure",
}

export enum AffectedArmEnum {
  RIGHT = "right",
  LEFT = "left",
  BOTH = "both",
}

// interfaces

export interface IControllerRules {
  required?: boolean | string;
  maxLength?: number | { value: number; message: string };
  minLength?: number | { value: number; message: string };
  pattern?: RegExp | { value: RegExp; message: string };
  validate?: any;
}

export interface ILoginFormValues {
  email: string;
  otp: number;
}

export interface INavData {
  label: string;
  url: string;
}

export interface ISpecialization {
  id: string | number;
  label: string;
}

export interface IEditDoctorForm {
  institution: string;
  position: string;
  specialization: IOption;
  specialization_other: string;
  clinics: IOption[];
  experience: ExperienceEnum;
  license: ILicense[];
  certifications: ICertificate[];
  app_goals: IOption[];
  app_goal_other: string;
}

export interface ISelectData {
  id: string | number;
  label: string;
}

export interface IExperienceData {
  id: number | string;
  label: string;
}

export interface IDoctorUserEditFormValues {
  avatar: IAvatar;
  bio: string;
  first_name: string;
  last_name: string;
  birth_day: Date;
  phone_number: string;
  email: string;
  business_phone: string;
  business_email: string;
}

export interface IMultiselectDataItem {
  id: number | string;
  label: string;
}

export interface ILicenseItem {
  dir: string;
  name: string;
  type: string;
}

export interface ILicense {
  file: ILicenseItem;
  licenseNumber: number;
}

export interface ICertificate {
  file: ILicenseItem;
  info: string;
}

export interface IAppGoalsData {
  id: number | string;
  label: string;
}

export interface ILicenseFields {
  license: ILicense;
}

export interface ICertificateFields {
  certifications: ICertificate;
}

export interface IEditEmailForm {
  email: string;
}

export interface IEditUserPayload {
  id: string | undefined;
  payload: {
    avatar?: IAvatar;
    bio?: string;
    first_name?: string;
    last_name?: string;
    birth_day?: string;
    phone_number?: string;
    email?: string
  };
}

export interface IOption {
  id: string;
  label: string;
}

export interface IQuestion {
  type: "radio" | "checkbox" | "text" | "date";
  key: string;
  title: string;
  label: string;
  options: IOption[];
}

export interface IEditPatientInfoValues {
  affected_arm: AffectedArmEnum;
  dominant_arm: DominantArmEnum;
  amputation_type: IPatentInfoItem;
  prosthetic_type: IPatentInfoItem;
  prosthetic_brand: IPatentInfoItem;
  medical_history: MedicalHistoryEnum;
  device_fit: DeviceFitEnum;
  device_satisfaction: DeviceSatisfactionEnum;
  device_challenges: DeviceChallangesEnum;
  training_needs: IOption;
  app_goals: IOption;
}

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface DoctorFiltrationValues {
  specialization: { id: string };
  clinics: { id: string }[];
  experience: { id: string };
  app_goals: { id: string }[];
}

export interface IReasonFormValues {
  tag: string;
  title_en: string;
  title_ru: string;
  title_am: string;
}
