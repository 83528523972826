import { createSlice } from "@reduxjs/toolkit";
import { StatusesEnum } from "../../components/interfaces";
import { IPatientsFilter } from "../interfaces";

interface IInitState {
  patientsFilter: IPatientsFilter;
}

const initialState: IInitState = {
  patientsFilter: {
    status: StatusesEnum.ACTIVE,
    page: 1,
  },
};
const patientsFilterSlice = createSlice({
  name: "patientsFilter",
  initialState,
  reducers: {
    savePatientsFilters(state, action) {
      if (action.payload.affected_arm)
        state.patientsFilter.affected_arm = action.payload.affected_arm;
      if (action.payload.dominant_arm)
        state.patientsFilter.dominant_arm = action.payload.dominant_arm;
      if (action.payload.amputation_type)
        state.patientsFilter.amputation_type = action.payload.amputation_type;
      if (action.payload.prosthetic_type)
        state.patientsFilter.prosthetic_type = action.payload.prosthetic_type;
      if (action.payload.prosthetic_brand)
        state.patientsFilter.prosthetic_brand = action.payload.prosthetic_brand;
      if (action.payload.medical_history)
        state.patientsFilter.medical_history = action.payload.medical_history;
      if (action.payload.device_fit)
        state.patientsFilter.device_fit = action.payload.device_fit;
      if (action.payload.device_satisfaction)
        state.patientsFilter.device_satisfaction =
          action.payload.device_satisfaction;
      if (action.payload.device_challenges)
        state.patientsFilter.device_challenges =
          action.payload.device_challenges;
      if (action.payload.training_needs)
        state.patientsFilter.training_needs = action.payload.training_needs;
      if (action.payload.app_goals)
        state.patientsFilter.app_goals = action.payload.app_goals;
    },
    resetPatientsFilters(state) {
      state.patientsFilter = initialState.patientsFilter;
    },
  },
});

export const { savePatientsFilters, resetPatientsFilters } =
  patientsFilterSlice.actions;

export default patientsFilterSlice.reducer;
