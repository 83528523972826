import { useEffect } from 'react';
import UseGeneralHooks from '../../utils/generalHooks';
import { resetPatientsFilters } from '../../services/Slices/patientsFilterSlice';

type Props = {
  children?: React.ReactNode;
};

const PrivatePage: React.FC<Props> = ({ children }) => {
  const { navigate, access_token, location, dispatch } = UseGeneralHooks();  

  useEffect(() => {
    if (!access_token) {
      navigate('/login');
    } else {
      localStorage.setItem("fileType", "image")
      if(!location?.pathname?.includes('patients')){
        dispatch(resetPatientsFilters())
      }
    }
  }, [access_token]);

  return <div style={{ width: '100%', height: "100%" }}>{children}</div>;
};

export default PrivatePage;
