import { useEffect } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { IEditPatientInfoValues, IOption, IQuestion } from "../interfaces";
import { ISinglePatientDataResp } from "../../services/interfaces";
import { useEditPatientInfoMutation } from "../../services/Requsts/patientApi";

interface IProps {
  patientSingleData: ISinglePatientDataResp | undefined;
}

const UsePatientEditHooks = (props: IProps) => {
  const { patientSingleData } = props;

  const [editPatientReq, { isLoading, isSuccess }] =
    useEditPatientInfoMutation();

  const getDefaultValueForField = (question: IQuestion, defaultValues: any) => {
    if (question.type === "radio") {
      const radioValue = defaultValues?.result?.[question.key];
      return radioValue !== undefined ? radioValue : null;
    }

    if (question.type === "checkbox") {
      const defaultCheckboxValues = defaultValues?.result?.[question.key];

      if (Array.isArray(defaultCheckboxValues)) {
        return defaultCheckboxValues
          .map((item: any) => {
            if (typeof item === "object" && item !== null && "id" in item) {
              return item.id;
            }
            return null;
          })
          .filter((id) => id !== null);
      }
      return [];
    }
    return null;
  };

  const handleCheckboxChange = (
    onChange: any,
    selectedValues: any[],
    item: IOption
  ) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      let updatedValues;

      if (isChecked) {
        updatedValues = [...selectedValues, item];
      } else {
        updatedValues = selectedValues.filter((value) => value.id !== item.id);
      }
      onChange(updatedValues);
    };
  };

  const handleOtherCheckboxChange =
    (onChange: any, selectedValues: any[], fieldKey: string) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;

      const otherOption = {
        id: `other_${fieldKey}`,
        label: "",
      };

      const newSelectedValues = isChecked
        ? [...selectedValues, otherOption]
        : selectedValues.filter((val) => val.id !== otherOption.id);

      onChange(newSelectedValues);
    };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm<FieldValues | IEditPatientInfoValues>({
    defaultValues: {
      affected_arm: patientSingleData?.result?.affected_arm,
      dominant_arm: patientSingleData?.result?.dominant_arm,
      amputation_type: patientSingleData?.result?.amputation_type,
      prosthetic_type: patientSingleData?.result?.prosthetic_type,
      prosthetic_brand: patientSingleData?.result?.prosthetic_brand,
      medical_history: patientSingleData?.result?.medical_history,
      device_fit: patientSingleData?.result?.device_fit,
      device_satisfaction: patientSingleData?.result?.device_satisfaction,
      device_challenges: patientSingleData?.result?.device_challenges,
      training_needs: patientSingleData?.result?.training_needs,
      app_goals: patientSingleData?.result?.app_goals,
    },
  });

  const fieldsWithOther = [
    "amputation_type",
    "prosthetic_type",
    "prosthetic_brand",
    "device_challenges",
    "training_needs",
    "app_goals",
  ];

  const onSubmit = (data: FieldValues | IEditPatientInfoValues) => {
    let hasError = false;

    fieldsWithOther.forEach((item: string) => {
      // @ts-ignore
      data?.[item]?.forEach((obj: IOption) => {
        if (obj.id === `other_${item}` && !obj.label.trim()) {
          setError(item, {
            type: "required",
            message: `Պարտադիր է`,
          });
          hasError = true;
        }
      });
    });

    if (!hasError) {
      editPatientReq({
        id: patientSingleData?.result?.id,
        payload: data,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Պացիենտի ինֆորմացիան հաջողությամբ թարմացված է");
    }
  }, [isSuccess]);

  return {
    handleOtherCheckboxChange,
    getDefaultValueForField,
    handleCheckboxChange,
    handleSubmit,
    setValue,
    onSubmit,
    errors,
    control,
    isLoading,
  };
};

export default UsePatientEditHooks;
