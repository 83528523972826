import { Control, Controller, FieldError, FieldErrors, FieldValues } from 'react-hook-form';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { IControllerRules } from '../interfaces';
import styles from './customDataPicker.module.scss';

interface IProps {
  label?: string;
  controllerName: string;
  control: Control<FieldValues, any>;
  controllerRules: IControllerRules;
  errors?: FieldErrors<FieldValues>;
}

const CustomDatePicker: React.FC<IProps> = (props) => {
  const { label, control, controllerName, controllerRules, errors } = props;
  return (
    <div className={styles['input-container']}>
      {label && <label className={styles['label']}>{label}</label>}
      <Controller
        key={controllerName}
        control={control}
        name={controllerName}
        rules={controllerRules}
        render={({ field: { onChange, name, value } }) => {
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                defaultValue={dayjs(new Date())}
                onChange={onChange}
                value={dayjs(value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    border: '1px solid rgb(162, 165, 177) !important',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: '1px solid rgb(162, 165, 177) !important',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      border: '2px solid #053D57 !important',
                      boxShadow: '0 0 5px rgba(80, 91, 241, 1) !important',
                    },
                  },
                }}
              />
            </LocalizationProvider>
          );
        }}
      />
      {(errors?.[controllerName] as FieldError)?.message && (
        <label className={styles["input-error"]}>
          {(errors?.[controllerName] as FieldError).message}
        </label>
      )}
    </div>
  );
};

export default CustomDatePicker;
