import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldValues,
  UseFormSetValue,
} from "react-hook-form";
import { IControllerRules, InputTypeEnum, IOption } from "../interfaces";
import { CustomInput } from "../index";
import styles from "./customCheckbox.module.scss";

interface IProps {
  label: string;
  controllerName: string;
  control: Control<FieldValues, any>;
  controllerRules: IControllerRules;
  errors?: FieldErrors<FieldValues>;
  data: IOption[];
  defaultValue?: any;
  handleCheckboxChange: (
    onChange: any,
    selectedValues: any[],
    item: IOption
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  setValue: UseFormSetValue<FieldValues | any>;
  handleOtherCheckboxChange?: (
    onChange: any,
    selectedValues: any[],
    fieldKey: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  horizontal?: boolean;
}

const CustomChechbox: React.FC<IProps> = (props) => {
  const {
    data,
    label,
    errors,
    control,
    defaultValue,
    controllerName,
    controllerRules,
    horizontal = false,
    handleCheckboxChange,
    handleOtherCheckboxChange,
    setValue,
  } = props;  

  return (
    <div className={styles["checkbox-container"]}>
      {label && <label className={styles["label"]}>{label}</label>}
      <Controller
        key={controllerName}
        control={control}
        name={controllerName}
        rules={controllerRules}
        render={({ field: { onChange, name, value } }) => {
          const selectedValues = value || defaultValue || [];
          return (
            <>
              <Box sx={{ display: "flex" }}>
                <FormControl component="fieldset" variant="filled">
                  <FormGroup
                    sx={
                      horizontal
                        ? { display: "flex", flexDirection: "row" }
                        : { display: "flex", flexDirection: "column", justifyContent: "flex-end" }
                    }
                  >
                    {data?.map((item: IOption, index: number) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedValues.some(
                                (val: IOption) => val.id === item.id
                              )}
                              onChange={handleCheckboxChange(
                                onChange,
                                selectedValues,
                                item
                              )}
                              value={value}
                              key={index}
                            />
                          }
                          label={item?.label}
                        />
                      );
                    })}
                    {handleOtherCheckboxChange && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedValues.some(
                              (val: IOption) =>
                                val.id === `other_${controllerName}`
                            )}
                            onChange={handleOtherCheckboxChange(
                              onChange,
                              selectedValues,
                              controllerName
                            )}
                            value={value}
                          />
                        }
                        label={"Այլ"}
                      />
                    )}
                  </FormGroup>
                </FormControl>
              </Box>
              {selectedValues?.some(
                (obj: IOption) => obj?.id === `other_${controllerName}`
              ) && (
                <CustomInput
                  type={InputTypeEnum.text}
                  controllerName={controllerName}
                  control={control}
                  controllerRules={{ required: false }}
                  label="Այլ"
                  errors={errors}
                  setValue={setValue}
                  selectedValues={selectedValues}
                  hasOtherField
                  defaultValue={
                    selectedValues.find((obj: IOption) => obj?.id === `other_${controllerName}`)?.label || ""
                  }
                />
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default CustomChechbox;
