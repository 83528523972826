import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../API";
import {
  ICreateReasonPayload,
  IEditReasonPayload,
  IReasonByIdResp,
  IReasonListAllResp,
  IReasonsListResp,
} from "../interfaces";

export const reasonsApi = createApi({
  reducerPath: "reasonsApi",
  tagTypes: ["reasons"],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    getReasonsList: builder.query<IReasonsListResp, number>({
      query(page) {
        return {
          url: `/post/admin/reasons?limit=10&page=${page}`,
          method: "GET",
        };
      },
      providesTags: ["reasons"],
    }),
    getReasonsListAll: builder.query<IReasonListAllResp, void>({
      query() {
        return {
          url: `/post/admin/reasons/all`,
          method: "GET",
        };
      },
      providesTags: ["reasons"],
    }),
    getReasonById: builder.query<IReasonByIdResp, string>({
      query(id) {
        return {
          url: `/post/report/reasons/${id}`,
          method: "GET",
        };
      },
      providesTags: ["reasons"],
    }),
    addReason: builder.mutation<IReasonsListResp, ICreateReasonPayload>({
      query(payload) {
        return {
          url: `/post/report/reasons`,
          data: payload,
          method: "POST",
        };
      },
      invalidatesTags: ["reasons"],
    }),
    deleteReason: builder.mutation<number, string>({
      query(id) {
        return {
          url: `/post/report/reasons/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["reasons"],
    }),
    editReason: builder.mutation<IReasonsListResp, IEditReasonPayload>({
      query({ id, payload }) {
        return {
          url: `/post/report/reasons/${id}`,
          data: payload,
          method: "PUT",
        };
      },
      invalidatesTags: ["reasons"],
    }),
  }),
});

export const {
  useAddReasonMutation,
  useEditReasonMutation,
  useGetReasonByIdQuery,
  useGetReasonsListQuery,
  useDeleteReasonMutation,
  useGetReasonsListAllQuery
} = reasonsApi;
