import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldValues,
} from "react-hook-form";
import { IControllerRules, InputTypeEnum, IOption } from "../interfaces";
import styles from "./customInput.module.scss";

interface IProps {
  label?: string;
  setValue?: any;
  disabled?: boolean,
  type: InputTypeEnum;
  selectedValues?: any;
  placeholder?: string;
  defaultValue?: string;
  controllerName: string;
  hasOtherField?: boolean;
  customInputClassname?: string;
  controllerRules: IControllerRules;
  errors?: FieldErrors<FieldValues>;
  control: Control<FieldValues, any>;
}

const CustomInput: React.FC<IProps> = (props) => {
  const {
    type,
    label,
    errors,
    control,
    setValue,
    placeholder,
    defaultValue,
    controllerName,
    selectedValues,
    controllerRules,
    disabled = false,
    customInputClassname,
    hasOtherField = false,
  } = props;

  return (
    <div className={styles["input-container"]}>
      {label && <label className={styles["label"]}>{label}</label>}
      <Controller
        key={controllerName}
        control={control}
        name={controllerName}
        rules={controllerRules}
        render={({ field: { onChange, name, value } }) => {
          return (
            <input
              type={type}
              placeholder={placeholder}
              onChange={
                hasOtherField
                  ? (e) => {
                      const inputValue = e.target.value;
                      const filteredArr = selectedValues.filter(
                        (item: IOption) => item.id !== `other_${controllerName}`
                      );
                      const updatedValue = [
                        ...filteredArr,
                        { id: `other_${controllerName}`, label: inputValue },
                      ];
                      onChange(updatedValue);
                      setValue(controllerName, updatedValue);
                    }
                  : onChange
              }
              value={hasOtherField ? value?.label : value}
              defaultValue={defaultValue}
              className={`${styles["custom-input"]} ${customInputClassname} ${
                errors?.[controllerName]?.message && styles["error"]
              }`}
              name={name}
              disabled={disabled}
            ></input>
          );
        }}
      />
      {(errors?.[controllerName] as FieldError)?.message && (
        <label className={styles["input-error"]}>
          {(errors?.[controllerName] as FieldError).message}
        </label>
      )}
    </div>
  );
};

export default CustomInput;
