import { useState } from "react";
import Slider from "react-slick";
import { IAvatar } from "../../services/interfaces";
import { downloadImageFromBunny } from "../../utils/downloadImgFromBunny";
import styles from "./customSlickSlider.module.scss";

interface IProps {
  imagesArr: IAvatar[];
}

const CustomSlider = (props: IProps) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index: number) => {
      setCurrentSlide(index);
    },
  };
  return (
    <>
      <Slider {...settings}>
        {props?.imagesArr?.map((item: IAvatar, index: number) => {
          return (
            <div key={index}>
              <img
                src={downloadImageFromBunny({
                  avatar: item,
                })}
                alt={"noImageIcon"}
                className={styles["slider-image"]}
              />
            </div>
          );
        })}
      </Slider>
      {props?.imagesArr?.length > 0 && (
        <div className={styles["images-count"]}>
          {currentSlide + 1}/{props.imagesArr.length}
        </div>
      )}
    </>
  );
};

export default CustomSlider;
