import { useState } from "react";
import { INavData } from "../../components/interfaces";
import UseGeneralHooks from "../../utils/generalHooks";

const UseMainPageHooks = () => {
  const navigationData: INavData[] = [
    {
      label: "Բժիշկների ցանկ",
      url: "doctors",
    },
    {
      label: "Պացիենտների ցանկ",
      url: "patients",
    },
    {
      label: "Բողոքարկումների ցանկ",
      url: "reports",
    },
    {
      label: "Պատճառների ցանկ",
      url: "reasons",
    },
  ];

  const {location} = UseGeneralHooks()

  const [active, setActive] = useState<string>(location?.pathname);
  const { navigate } = UseGeneralHooks();


  function logout() {
    localStorage.removeItem("armbionics-access-token");
    navigate("/login");
  }

  return {
    navigationData,
    active,
    setActive,
    logout
  };
};

export default UseMainPageHooks;
