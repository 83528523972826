import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../API";
import { IEditDoctorStatusPayload, IEditDoctorUserResp } from "../interfaces";
import { IEditUserPayload } from "../../components/interfaces";

export const usersApi = createApi({
  reducerPath: "usersApi",
  tagTypes: ["doctors", "patients", "users"],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    editUser: builder.mutation<IEditDoctorUserResp, IEditUserPayload>({
      query({ payload, id }) {
        return {
          url: `/user/admin/user/${id}`,
          method: "PUT",
          data: payload,
        };
      },
      invalidatesTags: ["doctors", "patients", "users"],
    }),
    changeUserStatus: builder.mutation<number, IEditDoctorStatusPayload>({
      query({ id, status }) {
        return {
          url: `/user/admin/user/status/${id}`,
          method: "PUT",
          data: {status},
        };
      },
      invalidatesTags: ["doctors", "patients"],
    }),
  }),
});

export const { useEditUserMutation, useChangeUserStatusMutation } = usersApi;
