import bunnyConfig from "./bunnyConfig";

export const downloadImageFromBunny = ({ avatar }: any) => {
  const bunnyBaseUrl = bunnyConfig.storage.baseUrl;
  try {
    return `${bunnyBaseUrl}/${avatar?.dir}/${avatar?.name}`;
  } catch (err) {
    console.log(err, "!!");
  }
};

export const downloadVideoFromBunny = ({ video }: any) => {
  const bunnyBaseUrl = bunnyConfig.stream.hostname;
  try {
    return `${bunnyBaseUrl}/${video?.id}/playlist.m3u8`;
  } catch (err) {
    console.log(err, "!!");
  }
};
