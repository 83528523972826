import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

interface IProps {
  count: number;
  onChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  page: number
}

const CustomPagination: React.FC<IProps> = (props) => {
  const { count, onChange, page } = props;
  return (
    <div>
      <Stack spacing={2}>
        <Pagination
          count={count}
          variant="outlined"
          shape="rounded"
          onChange={onChange}
          page={page}
        />
      </Stack>
    </div>
  );
};

export default CustomPagination;
