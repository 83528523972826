import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../API";
import { IEditDoctorUserResp, IEditPatientPayload, IGetPatientsPayload, ISinglePatientDataResp, IUsersListResp } from "../interfaces";



export const patientsApi = createApi({
  reducerPath: "patientsApi",
  tagTypes: ["patients"],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
   getPatientsList: builder.query<IUsersListResp, IGetPatientsPayload>({
      query({page, filter}) {
        return {
          url: `/user/admin/patient?limit=10&page=${page}&filter=${filter}`,
          method: "GET",
        };
      },
      providesTags: ["patients"],
    }),
    deletePatient: builder.mutation<IEditDoctorUserResp, string>({
      query(id) {
        return {
          url: `/user/admin/patient/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["patients"],
    }),
    getSinglePatientInfo: builder.query<ISinglePatientDataResp, string | undefined>({
      query(id) {
        return {
          url: `/user/admin/patient/${id}`,
          method: "GET",
        };
      },
      providesTags: ["patients"],
    }),
    EditPatientInfo: builder.mutation<ISinglePatientDataResp, IEditPatientPayload>({
      query({id, payload}) {
        return {
          url: `/user/admin/patient/${id}`,
          data: payload,
          method: "PUT",
        };
      },
      invalidatesTags: ["patients"],
    }),
  }),
});

export const {
  useGetPatientsListQuery,
  useDeletePatientMutation,
  useGetSinglePatientInfoQuery,
  useEditPatientInfoMutation
} = patientsApi;