import { Control, Controller, FieldError, FieldErrors, FieldValues } from 'react-hook-form';
import { IControllerRules } from '../interfaces';
import styles from './customTextField.module.scss';

interface IProps {
  placeholder?: string;
  label?: string;
  control: Control<FieldValues, any>;
  controllerName: string;
  controllerRules: IControllerRules;
  defaultValue?: string;
  errors?: FieldErrors<FieldValues>;
  customInputClassname?: string;
}

const CustomTextfield: React.FC<IProps> = (props) => {
  const {
    placeholder,
    label,
    control,
    controllerName,
    controllerRules,
    defaultValue,
    errors,
    customInputClassname,
  } = props;

  return (
    <div className={styles['input-container']}>
      {label && <label className={styles['label']}>{label}</label>}
      <Controller
        key={controllerName}
        control={control}
        name={controllerName}
        rules={controllerRules}
        render={({ field: { onChange, name, value } }) => {
          return (
            <textarea
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              defaultValue={defaultValue}
              className={`${
                styles['custom-textarea']
              } ${customInputClassname} ${
                errors?.[controllerName]?.message && styles['error']
              }`}
              name={name}
            ></textarea>
          );
        }}
      />
     {(errors?.[controllerName] as FieldError)?.message && (
        <label className={styles["input-error"]}>
          {(errors?.[controllerName] as FieldError).message}
        </label>
      )}
    </div>
  );
};

export default CustomTextfield;
