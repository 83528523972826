import { ClipLoader } from "react-spinners";
import {
  CustomButton,
  CustomModal,
  CustomPagination,
  ErrorComponent,
  ReasonForm,
  ReasonsTable,
} from "../../components";
import UseReasonHooks from "./reasonsPage.hooks";
import { ButtonTypeEnum } from "../../components/interfaces";
import UseCustomModalHooks from "../../components/CustomModal/customModal.hooks";
import styles from "./reasonsPage.module.scss";

const ReasonsPage: React.FC = () => {
  const { open, handleClose, handleClickOpen } = UseCustomModalHooks();
  const {
    handlePageChange,
    setEditReason,
    handleSubmit,
    setReasonId,
    onSubmit,
    reset,
    page,
    errors,
    control,
    editReason,
    reasonsList,
    addReasonLoading,
    reasonsListError,
    reasonsListLoading,
    reasonsEditLoading,
  } = UseReasonHooks({ handleClose });

  if (reasonsListLoading) {
    return (
      <div className="loader-page">
        <ClipLoader color={"grey"} size={50} />
      </div>
    );
  }

  if (reasonsListError) {
    return (
      <div className="loader-page">
        <ErrorComponent />
      </div>
    );
  }

  return (
    <div className="outlet-page">
      <div className={styles["reasons-title__cont"]}>
        <h1 className="page-heading">
          Ծանոթացեք բողոքարկումների պատճառների ցանկին
        </h1>
        <CustomButton
          buttonText="Ավելացնել նոր պատճառ"
          type={ButtonTypeEnum.button}
          onClick={() => {
            handleClickOpen();
            reset();
          }}
          loading={false}
        />
      </div>
      <ReasonsTable
        reasonsList={reasonsList?.result?.rows}
        openEditModal={handleClickOpen}
        setEditReason={setEditReason}
        setReasonId={setReasonId}
        page={page}
      />
      {reasonsList?.result?.count! > 10 && (
        <CustomPagination
          count={Math.ceil(reasonsList?.result?.count! / 10)}
          onChange={handlePageChange}
          page={page}
        />
      )}
      <CustomModal
        open={open}
        handleClose={handleClose}
        onConfirm={handleSubmit(onSubmit)}
        loading={editReason ? reasonsEditLoading : addReasonLoading}
        title={editReason ? "" : "Ավելացնել նոր պատճառ"}
        cancel={reset}
        setEditReason={setEditReason}
      >
        <ReasonForm
          onSubmit={onSubmit}
          control={control}
          errors={errors}
          handleSubmit={handleSubmit}
        />
      </CustomModal>
    </div>
  );
};

export default ReasonsPage;
