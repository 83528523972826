import searchIcon from "../../assets/images/search-svgrepo-com.svg";
import styles from "./searchInput.module.scss";

interface IProps {
    setSearchInputValue: (val: string) => void
}

const SearchInput: React.FC<IProps> = (props) => {
    return(
        <div className={styles.searchContainer}>
            <img className={styles.searchIcon} src={searchIcon} />
            <input type="text" placeholder="Փնտրել..." className={styles.searchInput} onChange={(e: React.ChangeEvent<HTMLInputElement>) => props?.setSearchInputValue(e?.target?.value)}/>
        </div>
    )
}

export default SearchInput;