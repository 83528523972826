import styles from "./emptyData.module.scss";
import emptydataImg from "../../assets/images/no-data-concept.avif"; 

const EmptyData: React.FC = () => {
    return(
        <div className={styles["empty-data__container"]}>
            <img src={emptydataImg} className={styles["empty-data__img"]}/>
            <h1 className="page-heading">Ցանկը դատարկ է</h1>
        </div>
    )
}

export default EmptyData;