export default {
  storage: {
    uploadBaseUrl: process.env.REACT_APP_UPLOAD_BASEURL,
    baseUrl: process.env.REACT_APP_ARMBIONIC_BASEURL,
    authKey: process.env.REACT_APP_AUTH_KEY,
    storageZoneName: process.env.REACT_APP_STORAGE_ZONENAME,
    hostname: process.env.REACT_APP_HOSTNAME,
  },
  stream: {
    baseUrl: process.env.REACT_APP_STREAM_BASEURL,
    authKey: process.env.REACT_APP_STREAM_AUTHKEY,
    libraryId: process.env.REACT_APP_STREAM_LIBRARY_ID,
    hostname: process.env.REACT_APP_STREAM_HOSTNAME,
    collectionId: process.env.REACT_APP_STREAM_COLLECTION_ID,
  },
};
