import { useGetSingleDoctorInfoQuery } from "../../services/Requsts/doctorsApi";
import UseGeneralHooks from "../../utils/generalHooks";

const UseGetSingleDoctorHooks = () => {
  const { params } = UseGeneralHooks()
  const { data: doctorSingleData, isLoading: singleDoctorDataLoading, isError:  singleDoctorDataError} = useGetSingleDoctorInfoQuery(params?.id);
  

  return {
    doctorSingleData, singleDoctorDataLoading, singleDoctorDataError
  };
};

export default UseGetSingleDoctorHooks;