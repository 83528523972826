import { ClipLoader } from "react-spinners";
import UseGetSinglePatientHooks from "./patientSinglePage.hooks";
import { UserEdit, PatientsEdit, ErrorComponent } from "../../components";

const PatientsSinglePage: React.FC = () => {
  const { singlePatientDataLoading, patientSingleData, singlePatientDatarror } = UseGetSinglePatientHooks();

  if (singlePatientDataLoading) {
    return (
      <div className="loader-page">
        <ClipLoader color={"grey"} size={50} />
      </div>
    );
  }

  if (singlePatientDatarror) {
    return (
      <div className="loader-page">
        <ErrorComponent />
      </div>
    );
  }

  return (
    <div className="outlet-page">
      <h1 className="page-heading">Պացիենտի անձնական ինֆորմացիա</h1>
      <UserEdit userSingleData={patientSingleData} />
      <h1 className="page-heading">Հավելյալ տեղեկատվություն պացիենտի մասին</h1>
      <PatientsEdit patientSingleData={patientSingleData}/>
    </div>
  );
};

export default PatientsSinglePage;
