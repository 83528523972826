import { Link, Outlet } from 'react-router-dom';
import UseMainPageHooks from './mainPage.hooks';
import { INavData } from '../../components/interfaces';
import styles from './mainPage.module.scss';

const MainPage: React.FC = () => {
  const { navigationData, active, setActive, logout } = UseMainPageHooks();  

  return (
    <div className={styles['main-page']}>
      <div className={styles['main-page__navbar']}>
        {navigationData.map((item: INavData, index: number) => {
          return (
            <div className={styles['navbar-item__container']}>
              <Link
                to={item.url}
                key={index}
                className={styles['navbar-item']}
                onClick={() => setActive(item?.url)}
                style={
                  active?.includes(item?.url)
                    ? { backgroundColor: '#053D57' }
                    : { backgroundColor: 'transparent' }
                }
              >
                {item.label}
              </Link>
            </div>
          );
        })}
        <div style={{ paddingTop: '80px' }}>
          <button className={styles['logout-button']} onClick={logout}>
            Ելք
          </button>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default MainPage;
