import { CustomButton, CustomInput } from "../../index";
import { ButtonTypeEnum, InputTypeEnum } from "../../interfaces";
import { Control, FieldErrors, FieldValues } from "react-hook-form";

interface IProps {
  control: Control<FieldValues, any>;
  errors?: FieldErrors<FieldValues>;
  loading: boolean;
  sendEmail: () => void,
}

const LoginForm: React.FC<IProps> = (props) => {
  const { control, errors, loading, sendEmail } = props;
  return (
    <>
      <CustomInput
        type={InputTypeEnum.email}
        placeholder="Էլ հասցե"
        control={control}
        controllerName={"email"}
        controllerRules={{
          required: "Պարտադիր է",
          pattern: {
            value: /^\s*[A-ZA-z0-9._%+-]+@[A-Za-z0-9.-]+[A-Za-z]{2,}\s*$/i,
            message: "Անվավեր էլ հասցե",
          },
        }}
        errors={errors}
      />
      <CustomButton
        buttonText={"Հաջորդ"}
        type={ButtonTypeEnum.button}
        onClick={sendEmail}
        loading={loading}
      />
    </>
  );
};

export default LoginForm;
