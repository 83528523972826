import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from '../API'
import { ISigninData, IVerifyOtpData, IVerifyOtpResponse } from '../interfaces'

export const authApi = createApi({
  reducerPath: 'authApi',
  tagTypes: ["auth"],
  baseQuery: axiosBaseQuery({ baseUrl: process.env.REACT_APP_BASE_URL }),
  endpoints: (builder) => ({
    signIn: builder.mutation<void, ISigninData>({
      query(body) {
        return {
          url: `/user/auth/admin/sign_in`,
          method: 'POST',
          data: body,
        }
      },
      invalidatesTags: ["auth"],
    }),
    verifyOTP: builder.mutation<IVerifyOtpResponse, IVerifyOtpData>({
      query(body) {
        return {
          url: `/user/auth/verify_otp`,
          method: 'POST',
          data: body,
        }
      },
      invalidatesTags: ["auth"],
    }),
  }),
})

export const {
  useSignInMutation,
  useVerifyOTPMutation
} = authApi