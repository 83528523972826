import { FieldValues, useForm } from "react-hook-form";
import { ILoginFormValues } from "../interfaces";
import { useEffect, useState } from "react";
import {
  useSignInMutation,
  useVerifyOTPMutation,
} from "../../services/Requsts/authApi";
import UseGeneralHooks from "../../utils/generalHooks";

const UseLoginFormHooks = () => {
  const [signInReq, { isLoading: signinLoading, isSuccess: signinSuccess }] =
    useSignInMutation();
  const [
    verifyOTPReq,
    { data: userData, isLoading: verifyOtpLoading, isSuccess: verifyOtpSuccess },
  ] = useVerifyOTPMutation();

  const {access_token, navigate} = UseGeneralHooks();

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm<FieldValues | ILoginFormValues>();

  const [next, setNext] = useState<boolean>(false);

  const onSubmit = (data: ILoginFormValues | FieldValues) => {
    verifyOTPReq({
      email: data?.email,
      otp: data?.otp
    })
  };

  function sendEmail() {
    signInReq({ email: getValues()?.email });
  }

  useEffect(() => {
    if (signinSuccess) {
      setNext(true);
    }
  }, [signinSuccess]);


  useEffect(() => {
    if (access_token) {
      navigate('/main/doctors');
    }
  }, [access_token]);

  useEffect(() => {
    if (verifyOtpSuccess && userData?.result?.accessToken) {
      localStorage.setItem("armbionics-access-token", userData?.result?.accessToken);
      navigate("/main/doctors")
    }
  }, [verifyOtpSuccess]);

  return {
    handleSubmit,
    errors,
    onSubmit,
    control,
    next,
    sendEmail,
    signinLoading,
    verifyOtpLoading,
  };
};

export default UseLoginFormHooks;
