import { useEffect, useState } from "react";
import {
  useDeleteReportMutation,
  useGetReportsListQuery,
} from "../../services/Requsts/reportsApi";
import { toast } from "react-toastify";

interface IProps {
  handleClose: () => void;
}

const UseReportsHooks = (props: IProps) => {
  const [page, setPage] = useState<number>(1);

  const {
    data: reportsList,
    isLoading: reportListLoading,
    isError: reportListError,
  } = useGetReportsListQuery();

  const [
    deleteReportReq,
    { isLoading: deleteReportLoading, isSuccess: reportIsDeletedSuccessfully },
  ] = useDeleteReportMutation();

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
  };

  useEffect(() => {
    if (reportIsDeletedSuccessfully) {
      toast.success("Բողոքարկումը հաջողությամբ ջնջված է");
      props?.handleClose();
    }
  }, [reportIsDeletedSuccessfully]);

  return {
    page,
    reportsList,
    reportListError,
    reportListLoading,
    deleteReportLoading,
    reportIsDeletedSuccessfully,
    handlePageChange,
    deleteReportReq,
  };
};

export default UseReportsHooks;
