import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { Box, Tab, Tabs } from "@mui/material";
import UsePatientHooks from "./patients.hooks";
import UseCustomModalHooks from "../../components/CustomModal/customModal.hooks";
import {
  CustomPagination,
  CustomTabPanel,
  EmptyData,
  ErrorComponent,
  PatientsFiltration,
  SearchInput,
  UsersTable,
} from "../../components";
import { PagesEnum } from "../../components/interfaces";
import styles from "./patients.module.scss";

const PatientsPage: React.FC = () => {
  const { open, handleClose, handleClickOpen } = UseCustomModalHooks();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const {
    changePatientStatusLoading,
    getPatientsListLoading,
    getPatientsListError,
    patientsList,
    reduxValues,
    control,
    status,
    errors,
    value,
    page,
    reset,
    refetch,
    onSubmit,
    a11yProps,
    handleChange,
    handleSubmit,
    setFormValue,
    handlePageChange,
    setSearchInputValue,
    handleCheckboxChange,
    changePatientStatusReq,
  } = UsePatientHooks({ handleClose, setOpenDrawer });

  if (getPatientsListLoading) {
    return (
      <div className="loader-page">
        <ClipLoader color={"grey"} size={50} />
      </div>
    );
  }

  if (getPatientsListError) {
    return (
      <div className="loader-page">
        <ErrorComponent />
      </div>
    );
  }

  return (
    <div className="outlet-page">
      <div className={styles["headin-container"]}>
        <h1 className="page-heading">Ծանոթացեք պացիենտների ցանկին</h1>
        <SearchInput setSearchInputValue={setSearchInputValue} />
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Ակտիվ բժիշկների ցանկ" {...a11yProps(0)} />
            <Tab label="Չհաստատված բժիշկների ցանկ" {...a11yProps(1)} />
            <Tab label="Ջնջված բժիշկների ցանկ" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          {changePatientStatusLoading ? (
            <div className="loader-page">
              <ClipLoader color={"grey"} size={50} />
            </div>
          ) : (
            <>
              <PatientsFiltration
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                control={control}
                errors={errors}
                setValue={setFormValue}
                handleCheckboxChange={handleCheckboxChange}
                loading={getPatientsListLoading}
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                reset={reset}
                reduxValues={reduxValues}
                refetch={refetch}
              />
              {patientsList?.result?.count === 0 ? (
                <div className="loader-page">
                  <EmptyData />
                </div>
              ) : (
                <>
                  <UsersTable
                    handleClickOpen={handleClickOpen}
                    data={patientsList?.result?.rows}
                    pageForNavigation={PagesEnum.PATIENTS}
                    handleClose={handleClose}
                    loading={changePatientStatusLoading}
                    open={open}
                    changeUserStatusReq={changePatientStatusReq}
                    activeStatus={status}
                    page={page}
                  />
                  {patientsList?.result?.count! > 10 && (
                    <CustomPagination
                      count={Math.ceil(patientsList?.result?.count! / 10)}
                      onChange={handlePageChange}
                      page={page}
                    />
                  )}
                </>
              )}
            </>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          {changePatientStatusLoading ? (
            <div className="loader-page">
              <ClipLoader color={"grey"} size={50} />
            </div>
          ) : (
            <>
              <PatientsFiltration
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                control={control}
                errors={errors}
                setValue={setFormValue}
                handleCheckboxChange={handleCheckboxChange}
                loading={getPatientsListLoading}
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                reset={reset}
                reduxValues={reduxValues}
                refetch={refetch}
              />
              {patientsList?.result?.count === 0 ? (
                <div className="loader-page">
                  <EmptyData />
                </div>
              ) : (
                <>
                  <UsersTable
                    handleClickOpen={handleClickOpen}
                    data={patientsList?.result?.rows}
                    pageForNavigation={PagesEnum.PATIENTS}
                    handleClose={handleClose}
                    loading={changePatientStatusLoading}
                    open={open}
                    changeUserStatusReq={changePatientStatusReq}
                    activeStatus={status}
                    page={page}
                  />
                  {patientsList?.result?.count! > 10 && (
                    <CustomPagination
                      count={Math.ceil(patientsList?.result?.count! / 10)}
                      onChange={handlePageChange}
                      page={page}
                    />
                  )}
                </>
              )}
            </>
          )}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          {changePatientStatusLoading ? (
            <div className="loader-page">
              <ClipLoader color={"grey"} size={50} />
            </div>
          ) : (
            <>
              <PatientsFiltration
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                control={control}
                errors={errors}
                setValue={setFormValue}
                handleCheckboxChange={handleCheckboxChange}
                loading={getPatientsListLoading}
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer}
                reset={reset}
                reduxValues={reduxValues}
                refetch={refetch}
              />
              {patientsList?.result?.count === 0 ? (
                <div className="loader-page">
                  <EmptyData />
                </div>
              ) : (
                <>
                  <UsersTable
                    handleClickOpen={handleClickOpen}
                    data={patientsList?.result?.rows}
                    pageForNavigation={PagesEnum.PATIENTS}
                    handleClose={handleClose}
                    loading={changePatientStatusLoading}
                    open={open}
                    changeUserStatusReq={changePatientStatusReq}
                    activeStatus={status}
                    page={page}
                  />
                  {patientsList?.result?.count! > 10 && (
                    <CustomPagination
                      count={Math.ceil(patientsList?.result?.count! / 10)}
                      onChange={handlePageChange}
                      page={page}
                    />
                  )}
                </>
              )}
            </>
          )}
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default PatientsPage;
