import { Control, Controller, FieldError, FieldErrors, FieldValues } from "react-hook-form";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { IControllerRules, IOption } from "../interfaces";
import styles from "./customRadioButton.module.scss";

interface IProps {
  errors?: FieldErrors<FieldValues>;
  label: string;
  defaultValue: IOption;
  controllerName: string;
  radioBtnData: IOption[];
  controllerRules: IControllerRules;
  control: Control<FieldValues, any>;
}

const CustomRadioButton: React.FC<IProps> = (props) => {
  const {
    label,
    controllerName,
    control,
    controllerRules,
    errors,
    radioBtnData,
    defaultValue
  } = props;

  return (
    <div className={styles["radio-btn__container"]}>
      {label && <label className={styles["label"]}>{label}</label>}
      <Controller
        key={controllerName}
        control={control}
        name={controllerName}
        rules={controllerRules}
        render={({ field: { onChange, name, value } }) => {
          return (
            <FormControl onChange={onChange}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                defaultValue={defaultValue}
                className="custom_radio-btn"
              >
                {radioBtnData?.map((item, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      value={item?.id}
                      control={<Radio />}
                      label={item?.label}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          );
        }}
      />
      {(errors?.[controllerName] as FieldError)?.message && (
        <label className={styles["input-error"]}>
          {(errors?.[controllerName] as FieldError).message}
        </label>
      )}
    </div>
  );
};

export default CustomRadioButton;
