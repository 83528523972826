import {
  CustomPhoneInput,
  CustomDatePicker,
  CustomTextfield,
  CustomButton,
  CustomInput,
  ImageUpload,
} from "../index";
import {
  ISingleDoctorInfoResp,
  ISinglePatientDataResp,
} from "../../services/interfaces";
import { ButtonTypeEnum, InputTypeEnum } from "../interfaces";
import UseUserHooks from "./userEdit.hooks";
import styles from "./userEdit.module.scss";

interface IProps {
  userSingleData: ISingleDoctorInfoResp | ISinglePatientDataResp | undefined;
}

const UserEdit: React.FC<IProps> = (props) => {
  const {
    handleSubmit,
    errors,
    control,
    setValue,
    onSubmit,
    isLoading,
    setError,
    setCode,
    code,
  } = UseUserHooks({ userSingleData: props?.userSingleData });

  return (
    <div className={styles["container"]}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles["user-edit-form"]}
      >
        <div className={styles["profile-img__container"]}>
          <ImageUpload
            control={control}
            setValue={setValue}
            controllerName={"avatar"}
            defaultValue={props?.userSingleData?.result?.user?.avatar}
          />
        </div>
        <div className={styles["personal-info"]}>
          <div className={styles["row"]}>
            <CustomInput
              type={InputTypeEnum.text}
              controllerName="first_name"
              control={control}
              controllerRules={{ required: "Պարտադիրն է" }}
              label="Անուն"
              errors={errors}
            />
            <CustomInput
              type={InputTypeEnum.text}
              controllerName="last_name"
              control={control}
              controllerRules={{ required: "Պարտադիրն է" }}
              label="Ազգանուն"
              errors={errors}
            />
          </div>
          <div className={styles["row"]}>
            <CustomPhoneInput
              controllerName="phone_number"
              control={control}
              controllerRules={{
                required: false,
              }}
              label="Հեռախոսահամար"
              errors={errors}
              setError={setError}
              code={code}
              setCode={setCode}
              disabled={false}
            />
            <CustomDatePicker
              controllerName="birth_day"
              control={control}
              controllerRules={{ required: "Պարտադիրն է" }}
              label="Ծննդյան տարեթիվ"
              errors={errors}
            />
          </div>
          <div className={styles["row"]}>
            <CustomPhoneInput
              controllerName="business_phone_number"
              control={control}
              controllerRules={{
                required: false,
              }}
              label="Բիզնես Հեռախոսահամար"
              errors={errors}
              setError={setError}
              code={code}
              setCode={setCode}
              disabled
              
            />
            <CustomInput
              type={InputTypeEnum.email}
              controllerName="business_email"
              control={control}
              controllerRules={{
                required: "Պարտադիր է",
                pattern: {
                  value:
                    /^\s*[A-ZA-z0-9._%+-]+@[A-Za-z0-9.-]+[A-Za-z]{2,}\s*$/i,
                  message: "Անվավեր էլ հասցե",
                },
              }}
              label="Բիզնես էլ հասցեն"
              errors={errors}
              disabled
            />
          </div>
          <div className={styles["row"]}>
            <CustomTextfield
              controllerName="bio"
              control={control}
              controllerRules={{ required: false }}
              label="Իմ մասին"
              errors={errors}
            />
          </div>
          <CustomButton
            buttonText={"Փոփոխել"}
            type={ButtonTypeEnum.submit}
            loading={isLoading}
          />
        </div>
      </form>
      {/* <EditEmail
        emailDefaultValue={props?.userSingleData?.result?.user?.email}
        id={props?.userSingleData?.result?.user?.id}
      /> */}
    </div>
  );
};

export default UserEdit;
