import { Routes, Route, Navigate } from 'react-router-dom';
import {
  MainPage,
  LoginPage,
  ReportsPage,
  DoctorsPage,
  PrivatePage,
  ReasonsPage,
  PatientsPage,
  DoctorsSinglePage,
  ReportsSinglePage,
  PatientsSinglePage,
} from '../pages';

const MainNavigation: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/login' />} />
      <Route path='/login' element={<LoginPage />} />
      <Route
        path='/main'
        element={
          <PrivatePage>
            <MainPage />
          </PrivatePage>
        }
      >
        <Route path='' element={<Navigate to='doctors' />} />
        <Route path='doctors/:id' element={<DoctorsSinglePage />} />
        <Route path='doctors' element={<DoctorsPage />} />
        <Route path='patients/:id' element={<PatientsSinglePage />} />
        <Route path='patients' element={<PatientsPage />} />
        <Route path='reports/:id' element={<ReportsSinglePage />} />
        <Route path='reports' element={<ReportsPage />} />
        <Route path='reasons' element={<ReasonsPage />} />
      </Route>
    </Routes>
  );
};

export default MainNavigation;
