import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import {
  IControllerRules,
  IEditDoctorForm,
  InputTypeEnum,
} from "../../interfaces";
import {CustomInput} from "../../index";
import uploadImageToBunny from "../../../utils/uploadImageToBunny";
import UseDoctorsCertificationsHooks from "./doctorCertifications.hooks";
import uploadFileImg from "../../../assets/images/file-upload-svgrepo-com.svg";
import addCircleIcon from "../../../assets/images/plus-circle-svgrepo-com.svg";
import styles from "./doctorCertifications.module.scss";

interface IProps {
  errors: FieldErrors<FieldValues>;
  control: Control<FieldValues, any>;
  controllerRules: IControllerRules;
  certificateFields: Record<"id", string>[];
  setValue: UseFormSetValue<FieldValues | IEditDoctorForm>;
  getValues: UseFormGetValues<FieldValues | IEditDoctorForm>;
  watch: UseFormWatch<FieldValues | IEditDoctorForm>;
  removeCertificate: UseFieldArrayRemove;
  addNewCertificateField: () => void;
}

const DoctorsCertificate: React.FC<IProps> = (props) => {
  const {
    errors,
    control,
    controllerRules,
    certificateFields,
    addNewCertificateField,
    removeCertificate,
    getValues,
    watch,
  } = props;

  const { handleDownload, handleAddCertificate } =
    UseDoctorsCertificationsHooks();    

  return (
    <div className={styles["license-item__container"]}>
      {certificateFields?.map((field, index) => {
        const watchedCertificates = watch(`certifications.${index}.file`);
        return (
          <div key={field?.id} className={styles["license-item"]}>
            <img
              className={styles["uploadFileImg"]}
              src={uploadFileImg}
              onClick={() => handleAddCertificate(index)}
            />
            {watchedCertificates?.name ? (
              <span>{watchedCertificates?.name?.slice(0, 15)}...</span>
            ) : (
              <span>Ընտրված ֆայլ չկա</span>
            )}
            <Controller
              key={`certifications.${index}.file`}
              control={control}
              name={`certifications.${index}.file`}
              rules={controllerRules}
              render={({ field: { onChange, name, value } }) => {
                return (
                  <input
                    id={`certifications-fileUpload-${index}`}
                    type="file"
                    style={{ display: "none" }}
                    onChange={async (
                      e: React.ChangeEvent<HTMLInputElement>
                    ) => {
                      try {
                        const filesArray = Array.from(e.target.files || []);
                        localStorage.setItem("fileType", "file");
                        await uploadImageToBunny({
                          files: filesArray,
                          dir: "files",
                        }).then((resp) => {
                          onChange(resp?.[0]);
                        });
                      } catch (error) {
                        console.error("Upload failed:", error);
                      }
                    }}
                  />
                );
              }}
            />
            <CustomInput
              type={InputTypeEnum.number}
              controllerName={`certifications.${index}.info`}
              control={control}
              controllerRules={{
                required: false,
              }}
              label="Ավելին"
              errors={errors}
            />
            <div className={styles["license-actions"]}>
              <span
                className={styles["download-licence"]}
                onClick={() =>
                  handleDownload(getValues()?.certifications?.[index]?.file)
                }
              >
                Ներբեռնել
              </span>
              <span
                className={styles["delete-licence"]}
                onClick={() => removeCertificate(index)}
              >
                Ջնջել
              </span>
            </div>
          </div>
        );
      })}
      <div className={styles["license-item"]}>
        <img src={addCircleIcon} onClick={addNewCertificateField} />
        <span className={styles["add-certification"]}>Ավելացնել</span>
      </div>
    </div>
  );
};

export default DoctorsCertificate;
