import errorIcon from "../../assets/images/crash-on-pc-vector.jpg"; 
import styles from "./errorComponent.module.scss";

const ErrorComponent: React.FC = () => {
    return(
        <div className={styles["error-container"]}>
            <img src={errorIcon} className={styles["error-img"]}/>
            <h1 className="page-heading">Ինչ-որ բան այն չէ, խնդրում ենք կրկին փորձել</h1>
        </div>
    )
}

export default ErrorComponent;