import {
  IReason,
  IReasonListAllResp,
  IReportByIdResp,
} from "../../services/interfaces";
import styles from "./reportReasonsList.module.scss";

interface IProps {
  reportReasonsList: IReasonListAllResp | undefined;
  reportByIdInfo: IReportByIdResp | undefined;
}

const ReportReasonsList: React.FC<IProps> = (props) => {
  const { reportReasonsList, reportByIdInfo } = props;

  return (
    <div className={styles["reports-list"]}>
      {reportReasonsList?.result?.map((item: IReason) => {
        return (
          <div
            key={item?.id}
            className={
              item?.title?.en === reportByIdInfo?.result?.reason?.title?.en
                ? styles["activee-reason"]
                : styles[""]
            }
          >
            {item?.title?.en}
          </div>
        );
      })}
    </div>
  );
};

export default ReportReasonsList;
