import { useState } from "react";

const UseCustomModalHooks = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return {
    handleClickOpen,
    handleClose,
    open
  };
};

export default UseCustomModalHooks;
