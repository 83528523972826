import { useEffect } from "react";
import { useGetSinglePatientInfoQuery } from "../../services/Requsts/patientApi";
import UseGeneralHooks from "../../utils/generalHooks";

const UseGetSinglePatientHooks = () => {
  const { params } = UseGeneralHooks()
  const { data: patientSingleData, isLoading: singlePatientDataLoading, refetch, isError: singlePatientDatarror } = useGetSinglePatientInfoQuery(params?.id);

  useEffect(() => {
    refetch()
  }, [params?.id])

  return {
    patientSingleData, singlePatientDataLoading, singlePatientDatarror
  };
};

export default UseGetSinglePatientHooks;