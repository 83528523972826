import UseDoctorAdditionalInfoHooks from "./doctorAdditionalInfoEdit.hooks";
import {
  CustomInput,
  CustomButton,
  CustomSelect,
  CustomMultiSelect,
  DoctorsLicenses,
} from "../../index";
import {
  appGoals,
  clinics,
  experienceData,
  specializations,
} from "../../../utils/generalData";
import { ISingleDoctorInfoResp } from "../../../services/interfaces";
import DoctorsCertificate from "../DoctorsCertifications/doctorCertifications";
import { ButtonTypeEnum, InputTypeEnum, IOption } from "../../interfaces";
import styles from "../doctorEdit.module.scss";

interface IProps {
  doctorSingleData: ISingleDoctorInfoResp | undefined;
}

const DoctorAdditionalInfoEdit: React.FC<IProps> = (props) => {
  const {
    errors,
    control,
    isLoading,
    licenseFields,
    certificateFields,
    addNewCertificateField,
    addNewLicenseField,
    removeCertificate,
    removeLicense,
    handleSubmit,
    getValues,
    setValue,
    onSubmit,
    watch,
  } = UseDoctorAdditionalInfoHooks({
    doctorSingleData: props?.doctorSingleData,
  });

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles["doctors-additional-info__form"]}
    >
      <div className={styles["row"]}>
        <CustomInput
          type={InputTypeEnum.text}
          controllerName="institution"
          control={control}
          controllerRules={{ required: false }}
          label="Ուսումնական հաստատություն"
          errors={errors}
        />
        <CustomInput
          type={InputTypeEnum.text}
          controllerName="position"
          control={control}
          controllerRules={{ required: false }}
          label="Պաշտոն"
          errors={errors}
        />
      </div>
      <div className={styles["row"]}>
        <CustomSelect
          controllerName="specialization"
          control={control}
          controllerRules={{ required: false }}
          label="Մասնագիտացումը"
          data={specializations}
          errors={errors}
        />
        {watch("specialization") === "other" && (
          <CustomInput
            type={InputTypeEnum.text}
            controllerName="specialization_other"
            control={control}
            controllerRules={{ required: "Պարտադիր է" }}
            label="Մասնագիտացում (այլ)"
            errors={errors}
          />
        )}
      </div>
      <div className={styles["row"]}>
        <CustomMultiSelect
          controllerName="clinics"
          control={control}
          controllerRules={{ required: false }}
          label="Կլինիկաներ"
          data={clinics}
          errors={errors}
        />
        <CustomSelect
          controllerName="experience"
          control={control}
          controllerRules={{ required: false }}
          label="Փորձ"
          data={experienceData}
          errors={errors}
        />
      </div>
      <div className={styles["doctor-license-row"]}>
        <label className={styles["label"]}>Բժիշկի լիցենզիաների ցանկը</label>
        <DoctorsLicenses
          control={control}
          controllerRules={{ required: false }}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          addNewLicenseField={addNewLicenseField}
          licenseFields={licenseFields}
          removeLicense={removeLicense}
          watch={watch}
        />
      </div>
      <div className={styles["doctor-certifications-row"]}>
        <label className={styles["label"]}>Բժիշկի սերտիֆիկատների ցանկը</label>
        <DoctorsCertificate
          control={control}
          controllerRules={{ required: false }}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          addNewCertificateField={addNewCertificateField}
          certificateFields={certificateFields}
          removeCertificate={removeCertificate}
          watch={watch}
        />
      </div>
      <div className={styles["doctor-app-goals-row"]}>
        <div
          style={
            watch("app_goals")?.includes("other") ||
            props?.doctorSingleData?.result?.app_goals?.some(
              (goal: IOption) => goal.id === "other"
            )
              ? { maxWidth: "50%" }
              : { maxWidth: "100%", width: "100%"}
          }
        >
          <CustomMultiSelect
            controllerName="app_goals"
            control={control}
            controllerRules={{ required: false }}
            label="Վեբ կայքից օգտվելու նպատակները"
            data={appGoals}
            errors={errors}
          />
        </div>
        {(watch("app_goals")?.includes("other") ||
          (!watch("app_goals") &&
            props?.doctorSingleData?.result?.app_goals?.some(
              (goal: IOption) => goal.id === "other"
            ))) && (
          <CustomInput
            type={InputTypeEnum.text}
            controllerName="app_goal_other"
            control={control}
            controllerRules={{ required: false }}
            label="Կայքից օգտվելու Ձեր նպատակը"
            errors={errors}
          />
        )}
      </div>
      <CustomButton
        buttonText={"Փոփոխել"}
        type={ButtonTypeEnum.submit}
        loading={isLoading}
      />
    </form>
  );
};

export default DoctorAdditionalInfoEdit;
