import { ReactNode } from "react";
import { Dialog } from "@mui/material";
import { CustomButton } from "../index";
import { ButtonTypeEnum, IReasonFormValues } from "../interfaces";
import styles from "./customModal.module.scss";
import { FieldValues, UseFormReset } from "react-hook-form";

interface IProps {
  open: boolean;
  title: string;
  loading: boolean;
  children?: ReactNode;
  onConfirm: () => void;
  handleClose: () => void;
  cancel?: void | UseFormReset<FieldValues | IReasonFormValues>;
  setEditReason?: (val: boolean) => void;
}

const CustomModal: React.FC<IProps> = (props) => {
  const {
    open,
    handleClose,
    children,
    onConfirm,
    title,
    loading,
    cancel,
    setEditReason,
  } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <h3 style={{ textAlign: "center", marginBottom: "30px" }}>{title}</h3>
        {children && children}
        <div className={styles["actions"]}>
          <CustomButton
            buttonText="Հաստատել"
            type={ButtonTypeEnum.button}
            onClick={onConfirm}
            loading={loading}
          />
          <CustomButton
            buttonText="Չեղարկել"
            type={ButtonTypeEnum.button}
            onClick={() => {
              handleClose();
              cancel && cancel();
              setEditReason && setEditReason(false);
            }}
            loading={false}
            className={styles["reject-btn"]}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default CustomModal;
