import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldValues,
  UseFormSetError,
} from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { IControllerRules, IDoctorUserEditFormValues } from "../interfaces";
import "react-phone-input-2/lib/style.css";
import styles from "./customPhoneInput.module.scss";

interface IProps {
  code: string,
  label?: string;
  disabled: boolean,
  defaultValue?: any;
  controllerName: string;
  setCode: (val: string) => void;
  controllerRules: IControllerRules;
  errors?: FieldErrors<FieldValues>;
  control: Control<FieldValues, any>;
  setError: UseFormSetError<FieldValues | IDoctorUserEditFormValues>;
}

const CustomPhoneInput: React.FC<IProps> = (props) => {
  const { label, errors, control, controllerName, controllerRules, setCode,code, disabled=false } =
    props;

  return (
    <div className={styles["input-container"]}>
      {label && <label className={styles["label"]}>{label}</label>}
      <Controller
        key={controllerName}
        control={control}
        name={controllerName}
        rules={controllerRules}
        render={({ field: { onChange, name, value } }) => {
          return (
            <PhoneInput
              country={code}
              value={value}
              onChange={(phone,country:any) => {
                onChange(phone);
                setCode(country.countryCode)
              }}
              enableAreaCodes={true}
              containerClass="custom-phone-input-container"
              inputClass="custom-phone-input"
              buttonClass="custom-flag-button"
              masks={{ am: "(..) ..-..-.." }}
              placeholder="+374"
              disabled={disabled}
            />
          );
        }}
      />
      {(errors?.[controllerName] as FieldError)?.message && (
        <label className={styles["input-error"]}>
          {(errors?.[controllerName] as FieldError).message}
        </label>
      )}
    </div>
  );
};

export default CustomPhoneInput;
