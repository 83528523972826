import { useState } from "react";
import { ButtonTypeEnum } from "../interfaces";
import CustomModal from "../CustomModal/CustomModal";
import UseGeneralHooks from "../../utils/generalHooks";
import { IReportRow } from "../../services/interfaces";
import CustomButton from "../CustomButton/customButton";
import { useDeleteReportMutation } from "../../services/Requsts/reportsApi";

interface IProps {
  reportsList: IReportRow[] | undefined;
  deleteReportReq: ReturnType<typeof useDeleteReportMutation>[0];
  deleteReportLoading: boolean;
  handleClickOpen: () => void;
  handleClose: () => void;
  open: boolean;
}

const ReportsTable: React.FC<IProps> = (props: IProps) => {
  const { navigate } = UseGeneralHooks();
  const {
    open,
    reportsList,
    deleteReportLoading,
    deleteReportReq,
    handleClickOpen,
    handleClose,
  } = props;
  const [selectedReportId, setSelectedReportId] = useState<string>("");

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Բողոքարկման հեղինակը</th>
          <th>Փոստի հեղինակը</th>
          <th>Փոստի պարունակությունը</th>
          <th>Ավելին</th>
          <th>Ջնջել</th>
        </tr>
      </thead>
      <tbody>
        {reportsList?.map((item: IReportRow, index: number) => {
          return (
            <tr key={index}>
              <td>
                {item?.user?.first_name} {item?.user?.last_name}
              </td>
              <td>
                {item?.post?.user?.first_name} {item?.post?.user?.last_name}
              </td>
              <td>
                {item?.post?.text_content ? item?.post?.text_content : "---"}
              </td>
              <td>
                <CustomButton
                  buttonText="Տեսնել ավելին"
                  type={ButtonTypeEnum.button}
                  onClick={() => navigate(`/main/reports/${item?.id}`)}
                  loading={false}
                />
              </td>
              <td>
                <CustomButton
                  buttonText={"Ջնջել"}
                  className="delete-btn"
                  type={ButtonTypeEnum.button}
                  onClick={() => {
                    setSelectedReportId(item?.id);
                    handleClickOpen();
                  }}
                  loading={false}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
      <CustomModal
        open={open}
        handleClose={handleClose}
        onConfirm={() => deleteReportReq(selectedReportId)}
        loading={deleteReportLoading}
        title={"Համոզվա՞ծ եք, որ ցանկանում եք ջնջել տվյալ բողոքարկումը"}
      ></CustomModal>
    </table>
  );
};

export default ReportsTable;
