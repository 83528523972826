import {
  ExperienceEnum,
  IExperienceData,
  IMultiselectDataItem,
  IOption,
} from "../components/interfaces";

export const specializations: IOption[] = [
  {
    id: "orthopedic_surgeon",
    label: "Orthopedic Surgeon",
  },
  {
    id: "prosthetist",
    label: "Prosthetist",
  },
  {
    id: "rehabilitation_specialist",
    label: "Rehabilitation Specialist",
  },
  {
    id: "physiotherapist",
    label: "Physiotherapist",
  },
  {
    id: "occupational_therapist",
    label: "Occupational Therapist",
  },
  {
    id: "other",
    label: "Այլ",
  },
];

export const clinics: IMultiselectDataItem[] = [
  { label: "Oliver Hansen", id: "Oliver Hansen" },
  { label: "Van Henry", id: "Van Henry" },
  { label: "April Tucker", id: "April Tucker" },
  { label: "Ralph Hubbard", id: "Ralph Hubbard" },
];

export const experienceData: IExperienceData[] = [
  {
    id: ExperienceEnum.YEARS_1_5,
    label: "1-5 years",
  },
  {
    id: ExperienceEnum.YEARS_6_10,
    label: "6-10 years",
  },
  {
    id: ExperienceEnum.YEARS_11_15,
    label: "11-15 years",
  },
  {
    id: ExperienceEnum.YEARS_16_20,
    label: "16-20 years",
  },
  {
    id: ExperienceEnum.YEARS_NORE_THAN_20,
    label: "More tahn 20 years",
  },
];

export const appGoals: IOption[] = [
  {
    id: "monitor_patient_progress",
    label: "Monitor patient progress",
  },
  {
    id: "provide_remote_consultations_or_support",
    label: "Provide remote consultations or support",
  },
  {
    id: "access_patient_data_and_analytics",
    label: "Access patient data and analytics",
  },
  {
    id: "track_the_effectiveness_of_prosthetic_devices",
    label: "Track the effectiveness of prosthetic devices",
  },
  {
    id: "collaborate_with_other_healthcare_professionals",
    label: "Collaborate with other healthcare professionals",
  },
  {
    id: "other",
    label: "Other",
  },
];
