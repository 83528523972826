import { ClipLoader } from "react-spinners";
import UseReportsHooks from "./reports.hooks";
import { CustomPagination, EmptyData, ErrorComponent, ReportsTable } from "../../components";
import UseCustomModalHooks from "../../components/CustomModal/customModal.hooks";

const ReportsPage: React.FC = () => {
  const { open, handleClose, handleClickOpen } = UseCustomModalHooks();
  const {
    page,
    reportsList,
    reportListError,
    reportListLoading,
    deleteReportLoading,
    handlePageChange,
    deleteReportReq,
  } = UseReportsHooks({handleClose});

  if (reportListLoading) {
    return (
      <div className="loader-page">
        <ClipLoader color={"grey"} size={50} />
      </div>
    );
  }

  if (reportListError) {
    return (
      <div className="loader-page">
        <ErrorComponent />
      </div>
    );
  }

  return (
    <div className="outlet-page">
      <h1 className="page-heading">Ծանոթացեք բողոքարկումների ցանկին</h1>
      {reportsList?.result?.count === 0 ? (
        <div className="loader-page">
          <EmptyData />
        </div>
      ) : (
        <>
          <ReportsTable
            reportsList={reportsList?.result?.rows}
            deleteReportReq={deleteReportReq}
            deleteReportLoading={deleteReportLoading}
            open={open}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
          />
          {reportsList?.result?.count! > 10 && (
            <CustomPagination
              count={Math.ceil(reportsList?.result?.count! / 10)}
              onChange={handlePageChange}
              page={page}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ReportsPage;
