import { useRef, useEffect } from 'react';
import Hls from 'hls.js';

interface IProps {
    src: string
}

const HlsVideoPlayer = (props: IProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(props?.src);
        hls.attachMedia(videoRef.current);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          videoRef?.current?.play();
        });

        return () => {
          hls.destroy();
        };
      } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
        videoRef.current.src = props?.src;
        videoRef.current.addEventListener('loadedmetadata', () => {
          videoRef?.current?.play();
        });
      }
    }
  }, [props?.src]);

  return (
    <video
      ref={videoRef}
      width={250}
      height={200}
      controls
      muted
      loop
    />
  );
};

export default HlsVideoPlayer;
